import {useAppContext} from '../../context/appContext';
import {useEffect, useState} from 'react';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {formatDateString} from '../../services/util/dateFormatting';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);


export default function Analytics({board}) {
    const {auth, api} = useAppContext()

    const myMemberId = auth.getId()

    const [statistics, setStatistics] = useState(null)

    useEffect(() => {
        api.getBoardStatistics(board.id)
            .then(data => {
                setStatistics(data)
            })
    }, []);

    if (!statistics) {
        return <AnalyticsPlaceholder/>
    }

    const myExpenses = statistics.expenses.find(e => e.boardMember.user?.id === myMemberId) ?? {
        value: 0,
        currency: {
            symbol: board.currency.symbol,
        },
    }

    return (
        <div className="row">
            <div className="col col-12 col-lg-6 mb-4">
                <TotalExpenses value={statistics.spending.total.value}
                               symbol={statistics.spending.total.currency.symbol}/>
            </div>
            <div className="col col-12 col-lg-6 mb-4">
                <MyExpenses value={myExpenses.value} symbol={myExpenses.currency.symbol}/>
            </div>
            <div className="col col-12 col-lg-6">
                <Daily data={statistics.daily}/>
            </div>
        </div>
    )
}

function TotalExpenses({value, symbol}) {
    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">
                    Всего потрачено
                </h5>
                <p className="card-text fs-2 fw-semibold">
                    {value / 100} {symbol}
                </p>
            </div>
        </div>
    )
}

function MyExpenses({value, symbol}) {
    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">
                    Мои расходы
                </h5>
                <p className="card-text fs-2 fw-semibold">
                    {value / 100} {symbol}
                </p>
            </div>
        </div>
    )
}

function Daily({data}) {
    const labels = data.map(d => formatDateString(d.date));

    const dataset = data.map(d => d.value / 100);

    return <ChartBlock labels={labels} dataset={dataset} title="Расходы по дням"/>
}

function ChartBlock({labels, dataset, title}) {
    const chartData = {
        labels: labels,
        datasets: [{
            data: dataset,
            lineTension: 0,
            backgroundColor: 'transparent',
            borderColor: '#007bff',
            borderWidth: 2,
            pointBackgroundColor: '#007bff',
        }],
    }

    const chartOptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                boxPadding: 3,
            },
        },
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">
                        {title}
                    </h5>
                    <p className="card-text pt-2">
                        <Line data={chartData} options={chartOptions}/>
                    </p>
                </div>
            </div>
        </>
    )
}

function AnalyticsPlaceholder() {
    return (
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )
}