import {useAppContext} from '../../../context/appContext';
import React, {useEffect, useState} from 'react';
import Modal from '../../Modal/Modal';
import InvalidFeedback from '../../Form/InvalidFeedback';

export default function CreatePaymentModal({data, isOpen, onClose, onSubmit}) {
    const {auth, api} = useAppContext()

    const defaultPayerId = data.members.find(member => member.user?.id === auth.getId()).id

    const allowedPayers = data.members.filter(m => m.id === defaultPayerId || m.isPhantom())

    const defaultDate = () => new Date(new Date().toString().slice(0, 24) + ' GMT').toISOString().slice(0, 16);

    const [amount, setAmount] = useState('')
    const [currencyId, setCurrencyId] = useState(data.mainCurrencyId)
    const [paidAt, setPaidAt] = useState(defaultDate())
    const [payerId, setPayerId] = useState(defaultPayerId)
    const [type, setType] = useState('expense')
    const [payeeId, setPayeeId] = useState(data.members.filter(m => m.id !== payerId)[0]?.id)
    const [title, setTitle] = useState('')
    const [expenseMembers, setExpenseMembers] = useState(data.members.map(m => ({
        boardMember: m,
        amount: 0,
        touched: false,
    })))
    const [unusedMembers, setUnusedMembers] = useState([])
    const [rate, setRate] = useState(1)
    const [strRate, setStrRate] = useState('1')
    const [rateSuggestions, setRateSuggestions] = useState([])
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})

    const mainCurrency = data.currencies.find(c => c.id === data.mainCurrencyId)

    const handleClose = () => {
        clearForm()
        onClose()
    }

    const removeExpenseMember = member => {
        setExpenseMembers(expenseMembers.filter(m => m.boardMember.id !== member.boardMember.id))
        setUnusedMembers([...unusedMembers, member.boardMember])
    }

    const addExpenseMember = member => {
        setExpenseMembers([...expenseMembers, {
            boardMember: member,
            amount: 0,
            touched: false,
        }])
        setUnusedMembers(unusedMembers.filter(m => m.id !== member.id))
    }

    const updateAmount = amount => {
        setAmount(amount)
    }

    const updateMemberAmount = (member, amount) => {
        setExpenseMembers(expenseMembers.map(m => {
            if (m.boardMember.id === member.boardMember.id) {
                return {
                    ...m,
                    amount,
                    touched: true,
                }
            }

            return m
        }))
    }

    const applyRateSuggestion = strRate => {
        setStrRate(strRate.replace(',', '.'))
    }

    const autocount = () => {
        const total = Number(amount)
        const touchedMembersCount = expenseMembers.filter(m => m.touched).length
        const untouchedMembersCount = expenseMembers.length - touchedMembersCount
        if (untouchedMembersCount === 0) {
            return
        }

        const touchedMembersAmount = expenseMembers.filter(m => m.touched).reduce((acc, m) => acc + Number(m.amount), Number(0))
        let perEach = 0,
            perLast = 0

        if (total > touchedMembersAmount) {
            const untouchedMembersAmount = total - touchedMembersAmount

            perEach = Math.round(untouchedMembersAmount * 100 / untouchedMembersCount) / 100
            perLast = Math.round((untouchedMembersAmount - perEach * (untouchedMembersCount - 1)) * 100) / 100
        }

        let i = 0
        setExpenseMembers(expenseMembers.map(member => {
            if (member.touched) {
                i++
                return member
            }

            if (i === untouchedMembersCount - 1) {
                i++
                return {
                    ...member,
                    amount: perLast,
                }
            } else {
                i++
                return {
                    ...member,
                    amount: perEach,
                }
            }
        }))
    }

    useEffect(() => {
        autocount()
    }, [amount, JSON.stringify(expenseMembers)]);

    useEffect(() => {
        if (currencyId === data.mainCurrencyId) {
            setStrRate("1")
        }
    }, [currencyId])

    useEffect(() => {
        if (!isOpen) {
            return
        }

        api.getBoardCurrencyRateSuggestions(data.boardId, currencyId).then(data => {
            setRateSuggestions(data)
        })
    }, [isOpen])

    useEffect(() => {
        if (strRate === "") {
            setRate(0)

            return
        }

        setRate(parseFloat(strRate))
    }, [strRate])

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (loading) {
            return
        }
        if (!validate()) {
            return
        }
        setLoading(true)

        const formattedPaidAt = new Date(paidAt).toISOString()

        const form = {
            amount: amount * 100,
            paidAt: formattedPaidAt,
            currencyId,
            currencyRate: String(rate).replace('.', ','),
            payerId,
        }

        if (type === 'expense') {
            form.title = title
            form.members = expenseMembers.map(m => ({
                id: m.boardMember.id,
                amount: Math.round(m.amount * 100),
            }))
        } else {
            form.payeeId = payeeId
        }

        try {
            await onSubmit(type, form)
            handleClose()
            clearForm()
        } catch (e) {
            if (e?.response?.status === 422) {
                console.error(e.response.data.errors)
            } else {
                console.error(e)
            }
        } finally {
            setLoading(false)
        }
    }

    const validate = () => {
        const newErrors = {};

        if (Number(amount) <= 0) {
            newErrors.amount = 'Сумма должна быть больше 0'
        }

        if (new Date() < new Date(paidAt)) {
            newErrors.paidAt = 'Дата не может быть в будущем'
        }

        if (currencyId !== data.mainCurrencyId && (rate <= 0)) {
            newErrors.currencyRate = 'Курс валюты должен быть больше 0';
        }

        if (type === 'expense') {
            if (title.length === 0) {
                newErrors.title = 'Название не может быть пустым'
            }

            if (expenseMembers.length === 0) {
                newErrors.members = 'Участники не могут быть пустыми'
            } else if (expenseMembers.length === 0) {
                newErrors.members = 'Должен быть как минимум один участник'
            } else if (expenseMembers.reduce((acc, m) => acc + Number(m.amount), 0) !== Number(amount)) {
                newErrors.members = 'Сумма траты не совпадает с суммой по участникам'
            }
        } else {
            if (payeeId === payerId) {
                newErrors.payeeId = 'Нельзя переводить самому себе'
            }
        }

        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const clearForm = () => {
        setAmount('')
        setCurrencyId(data.currencies[0].id)
        setPaidAt(defaultDate())
        setType('expense')
        setPayeeId(data.members.filter(m => m.id !== payerId)[0]?.id)
        setTitle('')
        setExpenseMembers(data.members.map(m => ({
            boardMember: m,
            amount: 0,
            touched: false,
        })))
        setUnusedMembers([])
        setErrors({})
    }

    return (
        <>
            {isOpen && (
                <Modal onClose={handleClose}>
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Добавление платежа
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        />
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-3">
                                <div className="col-8">
                                    <div className="form-floating">
                                        <input
                                            type="number"
                                            min="0"
                                            step="0.01"
                                            inputMode="decimal"
                                            className={errors.amount ? 'form-control is-invalid' : 'form-control'}
                                            id="amount"
                                            name="amount"
                                            placeholder="Amount"
                                            value={amount}
                                            autoFocus={true}
                                            onChange={e => updateAmount(e.target.value)}
                                        />
                                        <label htmlFor="amount" className="form-label">
                                            Сумма
                                        </label>
                                        <InvalidFeedback error={errors.amount}/>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-floating">
                                        {data.currencies.length > 1
                                            ? (
                                                <select
                                                    id="currencyId"
                                                    className="form-select"
                                                    value={currencyId}
                                                    multiple={false}
                                                    onChange={e => setCurrencyId(parseInt(e.target.value))}
                                                >
                                                    {data.currencies.map(currency => (
                                                        <option key={currency.id} value={currency.id}>
                                                            {currency.flagEmoji} {currency.iso3}
                                                        </option>
                                                    ))}
                                                </select>
                                            )
                                            : (
                                                <input
                                                    id="currencyId"
                                                    className="form-control"
                                                    value={data.currencies[0].flagEmoji + ' ' + data.currencies[0].iso3}
                                                    readOnly={true}
                                                />
                                            )
                                        }
                                        <label htmlFor="currencyId" className="form-label">
                                            Валюта
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {(currencyId !== data.mainCurrencyId) && <>
                                <div
                                    key="currencyRateCard"
                                    className="mb-3 card border-0 bg-secondary-subtle p-2"
                                >
                                    <div className="form-floating mb-1">
                                        <input
                                            id="currencyRate"
                                            className="form-control"
                                            type="number"
                                            aria-describedby="currencyRateHelp"
                                            value={strRate}
                                            onChange={e => setStrRate(e.target.value)}
                                        />
                                        <label
                                            htmlFor="currencyRate"
                                            className="form-label"
                                        >
                                            Курс валюты к основной валюте доски
                                        </label>
                                        <InvalidFeedback error={errors.currencyRate}/>
                                        {rate > 0 && amount > 0 && (
                                            <div id="currencyRateHelp" className="form-text ps-2">
                                                = {(Math.round(rate * amount * 100) / 100).toFixed(2)} {mainCurrency.iso3}
                                            </div>
                                        )}
                                    </div>

                                    <div>
                                        {rateSuggestions[currencyId].map((rs, index) => (
                                            <button
                                                key={index}
                                                type="button"
                                                className="btn btn-primary btn-sm me-1"
                                                onClick={() => applyRateSuggestion(rs.rate)}
                                            >
                                                {rs.rate}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </>}

                            <div className="form-floating mb-3">
                                <input
                                    id="paidAt"
                                    type="datetime-local"
                                    className={errors.paidAt ? 'form-control is-invalid' : 'form-control'}
                                    value={paidAt}
                                    onChange={e => setPaidAt(e.target.value)}
                                />
                                <label
                                    htmlFor="paidAt"
                                    className="form-label"
                                >
                                    Дата
                                </label>
                                <InvalidFeedback error={errors.paidAt}/>
                            </div>

                            {allowedPayers.length > 1 && (
                                <div className="form-floating mb-3">
                                    <select
                                        id="payerId"
                                        className={errors.payerId ? 'form-select is-invalid' : 'form-select'}
                                        value={payerId}
                                        onChange={e => setPayerId(e.target.value)}
                                    >
                                        {allowedPayers.map(member => (
                                            <option key={member.id} value={member.id}>
                                                {member.name()}
                                            </option>
                                        ))}
                                    </select>
                                    <label
                                        htmlFor="payerId"
                                        className="form-label"
                                    >
                                        {type === 'expense' ? 'Кто заплатил' : 'Кто перевел'}
                                    </label>
                                    <InvalidFeedback error={errors.payerId}/>
                                </div>
                            )}

                            <div className="btn-group d-flex mb-3" role="group" aria-label="Payment Type">
                                <button
                                    type="button"
                                    className={
                                        type === 'expense' ? 'btn btn-primary' : 'btn btn-outline-primary'}
                                    onClick={() => setType('expense')}
                                >
                                    Трата
                                </button>
                                <button
                                    type="button"
                                    className={type === 'transfer' ? 'btn btn-primary' : 'btn btn-outline-primary'}
                                    onClick={() => setType('transfer')}
                                >
                                    Перевод
                                </button>
                            </div>

                            {type === 'expense' && (
                                <>
                                    <div className="form-floating mb-3">
                                        <input
                                            id="title"
                                            type="text"
                                            className={errors.title ? 'form-control is-invalid' : 'form-control'}
                                            placeholder="."
                                            value={title}
                                            onChange={e => setTitle(e.target.value)}
                                        />
                                        <label htmlFor="title" className="form-label">
                                            Название
                                        </label>
                                        <InvalidFeedback error={errors.title}/>
                                    </div>

                                    <div className="mb-3">
                                        <p className="mb-1">Участники</p>

                                        {expenseMembers.map(member => (
                                            <div
                                                key={member.boardMember.id}
                                                className="input-group mb-1"
                                            >
                                                <div
                                                    className="input-group-text"
                                                    style={{
                                                        width: '50%',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                >
                                                    <i
                                                        className="bi bi-dash-circle-fill text-danger"
                                                        onClick={() => removeExpenseMember(member)}
                                                    />
                                                    <label
                                                        className="ms-2"
                                                    >
                                                        {member.boardMember.name()}
                                                    </label>
                                                </div>
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    className="form-control"
                                                    inputMode="decimal"
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                    value={member.amount}
                                                    onChange={e => updateMemberAmount(member, e.target.value)}
                                                />
                                            </div>
                                        ))}

                                        {errors.members && (
                                            <div className="invalid-feedback d-block">
                                                {errors.members}
                                            </div>
                                        )}

                                        {unusedMembers.length > 0 && (
                                            <>
                                                <hr/>

                                                {unusedMembers.map(member => (
                                                    <div key={member.id}>
                                                        <i
                                                            className="bi bi-plus-circle-fill text-primary"
                                                            onClick={() => addExpenseMember(member)}
                                                        />
                                                        {member.name()}
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                </>
                            )}

                            {type === 'transfer' && (
                                <>
                                    <div className="form-floating mb-3">
                                        <select
                                            id="payeeId"
                                            value={payeeId}
                                            className={errors.payeeId ? 'form-select is-invalid' : 'form-select'}
                                            onChange={e => setPayeeId(e.target.value)}
                                        >
                                            {data.members.filter(member => member.id !== payerId).map(member => (
                                                <option key={member.id} value={member.id}>
                                                    {member.name()}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor="payeeId" className="form-label">Кому</label>
                                        <InvalidFeedback error={errors.payeeId}/>
                                    </div>
                                </>
                            )}

                            <button
                                type="submit"
                                className="btn btn-success mt-3"
                                disabled={loading}
                            >
                                Сохранить
                            </button>
                        </form>
                    </div>
                </Modal>
            )}
        </>
    )
}