import {NavLink} from 'react-router-dom';
import LogoLight from '../../images/icon.png';
import ThemeSwitcher from '../Theme/ThemeSwitcher';
import Avatar from '../Avatar/Avatar';
import LogoutButton from '../Auth/LogoutButton';
import React from 'react';
import {useAppContext} from '../../context/appContext';

export default function Header() {
    const {auth} = useAppContext()

    const me = auth.getUser()

    return (
        <header className="p-2 mb-3 border-bottom">
            <div className="container">
                <div
                    className="d-flex flex-wrap align-items-center justify-content-between"
                >
                    <div className="d-flex">
                        <NavLink
                            to="/"
                            className="d-flex align-items-center link-body-emphasis text-decoration-none me-2"
                        >
                            <img className="rounded" src={LogoLight} alt="logo" width="40" height="40"/>
                        </NavLink>

                        <ul className="d-none d-sm-flex nav col-12 col-lg-auto me-lg-auto justify-content-center">
                            <li>
                                <NavLink
                                    end
                                    to="/"
                                    className={({isActive, isPending}) => {
                                        return 'nav-link ' + (isActive ? ' text-secondary' : ' text-body')
                                    }}
                                >
                                    Доски
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className="d-flex">
                        <ThemeSwitcher/>

                        <div className="dropdown d-flex h-100 text-end">
                            <div
                                className="d-flex link-body-emphasis text-decoration-none cursor-pointer align-middle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <Avatar
                                    className="d-inline me-2"
                                    emoji={me.avatar.emoji}
                                    color={me.avatar.color}
                                    size="medium"
                                />
                                <div className="my-auto">
                                    {me.name}
                                    {me.isPro() && (
                                        <i className="bi bi-star-fill ms-1 text-success"/>
                                    )}
                                </div>
                            </div>
                            <ul className="dropdown-menu text-small">
                                <li>
                                    <NavLink className="dropdown-item" to="/profile">
                                        Профиль
                                    </NavLink>
                                </li>
                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>
                                <li>
                                    <LogoutButton/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}