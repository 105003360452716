import type BoardMember from './BoardMember';
import type ExpenseMember from './ExpenseMember';
import type Price from './Price';

export default class Payment {
    id: Number;
    amount: Price;
    amountMainCurrency: Price;
    currency: Object;
    type: String;
    paidAt: String;
    // expense
    payer: BoardMember;
    title: ?String;
    members: ?Array<ExpenseMember>;
    regularExpenseId: ?Number;
    tags: ?Array;
    // transfer
    payee: ?BoardMember;
    isMercy: ?Boolean;

    avatarType(): string {
        if (this.type === 'expense') {
            return 'expense'
        } else if (this.isMercy) {
            return 'mercy'
        } else {
            return 'transfer'
        }
    }
}