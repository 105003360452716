import {useEffect, useRef, useState} from 'react';
import {getThemeFromDocument, setThemeToDocument, setThemeToLocalStorage} from './theme';
import {Tooltip} from 'bootstrap';

export default function ThemeSwitcher() {
    const [theme, setTheme] = useState(getThemeFromDocument())

    const tooltipRef = useRef()

    useEffect(() => {
        const tooltip = new Tooltip(tooltipRef.current, {
            title: 'Переключить тему',
            placement: 'bottom',
            trigger: 'hover',
        })

        return () => {
            tooltip.dispose()
        }
    }, []);

    const toggleTheme = () => {
        const newTheme = theme === 'dark' ? 'light' : 'dark'

        setThemeToDocument(newTheme)
        setThemeToLocalStorage(newTheme)
        setTheme(newTheme)
    }

    return (
        <button
            className="btn btn-link me-2 text-decoration-none"
            ref={tooltipRef}
            onClick={toggleTheme}
        >
            {theme === 'dark'
                ? <i className="bi bi-sun-fill text-warning"></i>
                : <i className="bi bi-moon-stars-fill text-dark"></i>}
        </button>
    )
}