export default function AvatarPlaceholder({size}) {
    let circleSize = 24;

    // eslint-disable-next-line default-case
    switch (size) {
        case 'medium':
            circleSize = 36;
            break;
        case 'large':
            circleSize = 60;
            break;
    }

    let borderRadius = circleSize / 2;
    let fontSize = circleSize / 1.5;

    return (
        <div
            className="text-center placeholder-glow"
            style={{
                height: `${circleSize}px`,
                width: `${circleSize}px`,
                borderRadius: `${borderRadius}px`,
                backgroundColor: 'lightgrey',
                lineHeight: `${circleSize}px`,
                fontSize: `${fontSize}px`,
            }}
        >
            <span className="placeholder"></span>
        </div>
    )
}