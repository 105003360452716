import {Outlet} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAppContext} from "../../context/appContext";
import FullPageLoader from "../Util/FullPageLoader";

export default function InitAuth() {
    const {auth} = useAppContext();

    const [isPending, setIsPending] = useState(true);

    useEffect(() => {
        auth.waitForInit().then(() => {
            setIsPending(false);
        });
    }, [auth]);

    return isPending
        ? <FullPageLoader/>
        : <Outlet/>
}