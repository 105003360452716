import PropTypes from 'prop-types';
import {useAppContext} from '../../../context/appContext';
import {useStateBool} from '../../../services/util/useStateBool';
import {useEffect, useState} from 'react';

export default function RegularExpenses({board}) {
    const {api} = useAppContext()

    const [initialLoading, startInitialLoading, finishInitialLoading] = useStateBool(true)
    const [regularExpenses, setRegularExpenses] = useState([])

    useEffect(() => {
        api.getRegularExpenses(board.id)
            .then(({regularExpenses}) => {
                setRegularExpenses(regularExpenses)
                finishInitialLoading()
            })
            .catch(e => {
                console.log(e)
            })
    }, []);

    if (initialLoading) {
        return (
            <div>loading...</div>
        )
    }

    return (
        <>
            <button
                className="btn btn-success mb-3"
            >
                Добавить
            </button>

            <div className="list-group list-group-flush">
                {regularExpenses.length === 0 && <EmptyRegularPayments/>}
            </div>
        </>
    )
}

RegularExpenses.propTypes = {
    board: PropTypes.object.isRequired,
}

function EmptyRegularPayments() {
    return (
        <div className="list-group-item">
            Нет регулярных платежей
        </div>
    )
}