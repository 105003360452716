import {Link} from 'react-router-dom';
import React from 'react';
import {useAppContext} from '../../context/appContext';
import {useStateBool} from '../../services/util/useStateBool';
import AuthUser from '../../services/models/AuthUser';

export default function PrivacySettings() {
    const {api, auth} = useAppContext()

    const privacy = auth.getUser().privacy

    const [loading, startLoading, finishLoading] = useStateBool(false)

    const settings = [
        {
            key: 'allowDirectInvite',
            title: 'Разрешить друзьям добавлять меня в доски',
        },
    ]

    const handleSwitch = async (setting) => {
        if (loading) {
            return
        }
        startLoading()

        const form = {
            privacy: {},
        }
        form.privacy[setting.key] = !privacy[setting.key]

        const newProfile = await api.updateProfile(form)
        auth.setUser(new AuthUser(newProfile))
        finishLoading()
    }

    return (
        <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                <div className="mt-3 mt-md-5">
                    <h4>
                        <Link
                            to={'/profile'}
                            className="text-decoration-none"
                        >←️</Link>
                        &nbsp;Конфиденциальность
                    </h4>
                </div>
                <div className="list-group">
                    {settings.map(setting => (
                        <div
                            key={setting.key}
                            className="list-group-item d-flex justify-content-between align-items-center"
                        >
                            <div className="d-inline">
                                <span className="">{setting.title}</span>
                            </div>
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={privacy[setting.key]}
                                    disabled={loading}
                                    onChange={() => handleSwitch(setting)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}