import type OAuth from './oauth';
import AuthUser from '../models/AuthUser';

const keyAccessToken = 'accessToken'
const keyRefreshToken = 'refreshToken'
const keyUser = 'authUser'

export default class Auth {
    #pending = true;
    #api = null;
    #user: ?AuthUser = null;
    #oauths = new Map();

    constructor(api) {
        this.#api = api

        const accessToken = localStorage.getItem(keyAccessToken)
        const refreshToken = localStorage.getItem(keyRefreshToken)
        const lcUserString = localStorage.getItem(keyUser)

        if (accessToken) {
            this.#api.setTokens(accessToken, refreshToken)

            const updateUser = async () => {
                try {
                    await this.#fetchUser()
                } catch (e) {
                    if (e?.response?.status === 401) {
                        try {
                            await this.#updateTokens()
                            await this.#fetchUser()
                        } catch (e) {
                            await this.logout()
                        }
                    }
                }

                this.#pending = false
            }

            try {
                if (!lcUserString) {
                    throw new Error('no user in local storage')
                }
                this.#user = new AuthUser(JSON.parse(lcUserString))
                // this.#pending = false
            } catch (e) {
                console.log(e)
            } finally {
                updateUser()
            }
        } else {
            this.#pending = false
        }
    }

    waitForInit() {
        return new Promise((resolve) => {
            const waiter = setInterval(() => {
                if (!this.#pending) {
                    clearInterval(waiter)
                    resolve()
                }
            }, 100)
        })
    }

    isAuthenticated() {
        return this.#api.hasTokens()
    }

    getUser() {
        if (!this.isAuthenticated()) {
            console.log('user is not authenticated')
            return null;
        }

        return this.#user;
    }

    setUser(user: AuthUser) {
        this.#user = user

        localStorage.setItem(keyUser, JSON.stringify(this.#user))
    }

    getId() {
        return this.getUser().id;
    }

    async #updateTokens() {
        const {accessToken, refreshToken} = await this.#api.authenticateByRefresh()

        await this.setTokens(accessToken, refreshToken)
    }

    async #fetchUser() {
        const res = await this.#api.getProfile()
        this.setUser(new AuthUser(res))
    }

    async authenticateCredentials(username, password) {
        const res = await this.#api.authenticateByCredentials({
            username,
            password,
        })

        await this.setTokens(res.accessToken, res.refreshToken)
    }

    async authenticateSso(provider: string, token: string) {
        const res = await this.#api.authenticateBySso(provider, token)

        await this.setTokens(res.accessToken, res.refreshToken)
    }

    addOAuthProvider(provider: string, oauth: OAuth) {
        this.#oauths.set(provider, oauth)
    }

    startOAuth(provider: string) {
        if (!this.#oauths.has(provider)) {
            throw new Error(`OAuth provider ${provider} not found`)
        }

        const oauth = this.#oauths.get(provider)

        window.location = oauth.getUrl()
    }

    parseOAuth(provider: string, url: URL): string {
        if (!this.#oauths.has(provider)) {
            throw new Error(`OAuth provider ${provider} not found`)
        }

        const oauth = this.#oauths.get(provider)

        return oauth.parseTokenFromUrl(url)
    }

    async setTokens(accessToken, refreshToken = '') {
        this.#api.setTokens(accessToken, refreshToken)

        localStorage.setItem(keyAccessToken, accessToken)
        localStorage.setItem(keyRefreshToken, refreshToken)

        await this.#fetchUser()
    }

    async logout() {
        try {
            await this.#api.logout()
        } catch (e) {
            console.log(e)
        }

        this.#api.unsetTokens()
        this.#user = null

        localStorage.removeItem(keyAccessToken)
        localStorage.removeItem(keyRefreshToken)
        localStorage.removeItem(keyUser)
    }
}