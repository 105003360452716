import React from 'react';
import BoardsList from './BoardsList';
import CreateBoardModal from './CreateBoard';

export default function Dashboard() {
    const [isCreateBoardModalOpen, setIsCreateBoardModalOpen] = React.useState(false);

    const [archivedFilter, setArchivedFilter] = React.useState('0');

    return (
        <>
            <CreateBoardModal
                isOpen={isCreateBoardModalOpen}
                onClose={() => setIsCreateBoardModalOpen(false)}
            />

            {/*<div className="mt-5 d-flex justify-content-between">*/}
            {/*    <h4>*/}
            {/*        Балансы*/}
            {/*    </h4>*/}
            {/*</div>*/}
            {/*<Balances/>*/}

            <div className="mt-5 mb-2 d-flex justify-content-between">
                <h1>
                    Доски&nbsp;
                    <div className="dropdown d-inline">
                        <button className="btn btn-lg btn-link p-0 dropdown-toggle hide-after" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-filter-circle"/>
                        </button>
                        <ul className="dropdown-menu">
                            {['0', '1'].map(v => (
                                <li
                                    key={v}
                                    className={`dropdown-item cursor-pointer ${archivedFilter === v ? 'active' : ''}`}
                                    onClick={() => setArchivedFilter(v)}
                                >
                                    {v === '0' ? 'Активные' : 'Архивные'}
                                </li>
                            ))}
                        </ul>
                    </div>
                </h1>
                <div className="my-auto">
                    <CreateBoardButton onClick={() => setIsCreateBoardModalOpen(true)}/>
                </div>
            </div>
            <BoardsList
                archivedFilter={archivedFilter}
            />
        </>
    );
}

function CreateBoardButton({onClick}) {
    return (
        <button
            className="btn btn-link ms-1 p-0 text-decoration-none"
            onClick={onClick}
        >
            Создать
        </button>
    );
}