export function hexToRgba(hex) {
    if (hex[0] === '#') {
        hex = hex.slice(1)
    }

    const r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    let a = 1;

    if (hex.length > 6) {
        a = parseInt(hex.slice(6, 8), 16) / 255;
    }

    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')';
}