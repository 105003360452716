import {useNavigate} from "react-router-dom";
import {useAppContext} from "../../context/appContext";
import {Tooltip} from 'bootstrap';
import {useEffect, useRef} from 'react';

export default function LogoutButton() {
    const {auth} = useAppContext()
    const navigate = useNavigate()

    const handleLogout = async e => {
        e.preventDefault();

        await auth.logout()

        navigate('/login')
    }

    return (
        <div
            className="dropdown-item"
            onClick={handleLogout}
        >
            Выйти
        </div>
    )
}