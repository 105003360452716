import React, {useState} from 'react';
import {Navigate, useNavigate, useSearchParams} from 'react-router-dom';
import {ReactComponent as Apple} from '../../svg/socials/apple.svg';
import {ReactComponent as Google} from '../../svg/socials/google.svg';
import {ReactComponent as Vkontakte} from '../../svg/socials/vkontakte.svg';
import {ReactComponent as Yandex} from '../../svg/socials/yandex.svg';
import {useAppContext} from '../../context/appContext';
import {useStateBool} from '../../services/util/useStateBool';
import ThemeSwitcher from '../Theme/ThemeSwitcher';
import {setRedirectData} from '../../services/auth/redirectData';

export default function Login() {
    const {auth} = useAppContext()

    const [searchParams] = useSearchParams()

    const redirectTo = searchParams.has('redirectTo') ? searchParams.get('redirectTo') : null;

    const [modeCredentials, modeCredentialsOn, modeCredentialsOff] = useStateBool(searchParams.has('credentials'))
    const [logoClicksCounter, setLogoClicksCounter] = useState(0)
    const incrementLogoClicksCounter = () => setLogoClicksCounter(logoClicksCounter + 1)
    const resetLogoClicksCounter = () => setLogoClicksCounter(0)
    const [timeoutId, setTimeoutId] = useState(null)

    const handleLogoClick = () => {
        if (modeCredentials) {
            return
        }

        incrementLogoClicksCounter()

        if (timeoutId) {
            clearTimeout(timeoutId)
            setTimeoutId(null)
        }

        if (logoClicksCounter === 10) {
            resetLogoClicksCounter()

            modeCredentialsOn()

            clearTimeout(timeoutId)
            setTimeoutId(null)

            return;
        }

        setTimeoutId(setTimeout(() => {
            resetLogoClicksCounter()
        }, 1000))
    }

    if (auth.isAuthenticated()) {
        return <Navigate to="/"/>
    }

    return (
        <>
            <header>
                <div className="d-flex">
                    <div className="ms-auto">
                        <ThemeSwitcher/>
                    </div>
                </div>
            </header>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-11 col-sm-8 col-md-6 col-lg-4 col-xl-3">
                        <div className="card my-auto border-0 mt-5 py-3 px-2 shadow-sm bg-secondary-subtle rounded-4">
                            <div className="card-body text-center">
                                <div className="d-flex">
                                    <img
                                        className="mb-4 mx-auto rounded"
                                        src={'/icon.png'}
                                        alt={'Где Деньги'}
                                        width={72}
                                        height={72}
                                        onClick={handleLogoClick}
                                    />
                                </div>
                                <h1 className="h3 mb-4 text-center">
                                    Где Деньги
                                </h1>
                                {modeCredentials
                                    ? <CredentialsForm
                                        redirectTo={redirectTo}
                                        onModeCredentialsOff={modeCredentialsOff}
                                    />
                                    : <SsoForm
                                        redirectTo={redirectTo}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function CredentialsForm({redirectTo, onModeCredentialsOff}) {
    const navigate = useNavigate()

    const {auth, toast} = useAppContext()

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const handleSubmit = async e => {
        e.preventDefault()

        if (loading) {
            return
        }
        setLoading(true)

        if (!username || !password) {
            return
        }

        try {
            await auth.authenticateCredentials(username, password)

            if (redirectTo) {
                navigate(redirectTo)
            } else {
                navigate('/')
            }
        } catch (e) {
            if (e?.response?.status === 400) {
                toast.error(e.response.data.error)
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className={'form-floating mb-3'}>
                <input
                    type="text"
                    autoComplete="username"
                    className={'form-control'}
                    placeholder="."
                    onChange={e => setUserName(e.target.value)}
                />
                <label>Имя пользователя</label>
            </div>
            <div className={'form-floating mb-3'}>
                <input
                    type="password"
                    autoComplete="current-password"
                    className={'form-control'}
                    placeholder="."
                    onChange={e => setPassword(e.target.value)}
                />
                <label>Пароль</label>
            </div>
            <div>
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                >
                    Войти
                </button>
                <button
                    className="btn btn-link text-decoration-none"
                    onClick={onModeCredentialsOff}
                >
                    Назад
                </button>
            </div>
        </form>
    )
}

function SsoForm({redirectTo}) {
    const {auth} = useAppContext()

    const providers = [
        {
            key: 'apple',
            name: 'Apple',
            logo: <Apple/>,
        },
        {
            key: 'google',
            name: 'Google',
            logo: <Google/>,
        },
        {
            key: 'yandex',
            name: 'Yandex',
            logo: <Yandex/>,
        },
        {
            key: 'vkontakte',
            name: 'VK',
            logo: <Vkontakte/>,
        },
    ]

    const handleSso = provider => {
        if (redirectTo) {
            setRedirectData(redirectTo)
        }

        auth.startOAuth(provider)
    }

    return (
        <>
            <div className="d-grid gap-2 col-12 mx-auto">
                {providers.map(provider => (
                    <button
                        key={provider.key}
                        className="btn btn-light px-2 py-2 text-decoration-none rounded-3 fw-medium align-middle"
                        onClick={() => handleSso(provider.key)}
                    >
                        {provider.logo}&nbsp;
                        Войти с {provider.name}
                    </button>
                ))}
            </div>
        </>
    )
}