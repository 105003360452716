import React, {useState} from 'react';
import Modal from '../../Modal/Modal';
import Avatar from '../../Avatar/Avatar';
import {useShare} from '../../../services/util/share';
import {useStateBool} from '../../../services/util/useStateBool';
import {useAppContext} from '../../../context/appContext';
import {removeSchemeFromUrl} from '../../../services/util/removeSchemeFromUrl';

export default function MemberModal({member, readonly, isOpen, onClose, canRemoveMember, onRemoveMember}) {
    const {toast} = useAppContext()

    const {allowed: shareAllowed, shareUrl} = useShare()

    const [copyIconClass, setCopyIconClass] = useState('bi bi-clipboard')
    const [isRemoving, startRemoving, finishRemoving] = useStateBool(false)

    const handleCopy = async () => {
        await navigator.clipboard.writeText(member.phantom.inviteUrl);
        setCopyIconClass('bi bi-clipboard-check')
        setTimeout(() => {
            setCopyIconClass('bi bi-clipboard')
        }, 1000)
    }

    const handleRemoveMember = async () => {
        if (!member || isRemoving) {
            return
        }
        startRemoving()

        try {
            await onRemoveMember(member)
            onClose()
        } catch (e) {
            if (e?.response?.status === 400) {
                toast.error(e.response.data.error)
            } else {
                toast.error('Ошибка при исключении пользователя')
            }
        } finally {
            finishRemoving()
        }
    }

    return (
        <>
            {isOpen && (
                <Modal onClose={onClose}>
                    <div className="modal-body">
                        <div className="d-flex mb-4">
                            <Avatar
                                emoji={member.avatar().emoji}
                                color={member.avatar().color}
                                size="large"
                                className="d-inline-block me-2"
                            />
                            <div>
                                <p className="h3 mb-0">{member.name()}</p>
                                <span className="badge bg-primary">{member.isUser() ? 'Пользователь' : 'Фантом'}</span>
                            </div>
                        </div>

                        {!readonly && member.isPhantom() && (
                            <>
                                <div className="h5">
                                    Приглашение
                                </div>
                                <div className="input-group mb-4">
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={removeSchemeFromUrl(member.phantom.inviteUrl)}
                                        readOnly
                                    />
                                    {shareAllowed && (
                                        <button
                                            className="btn btn-outline-secondary"
                                            onClick={() => shareUrl(member.phantom.inviteUrl)}
                                        >
                                            <i className="bi bi-share"></i>
                                        </button>
                                    )}
                                    <button
                                        className="btn btn-outline-secondary"
                                        onClick={handleCopy}
                                    >
                                        <i className={copyIconClass}></i>
                                    </button>
                                </div>
                            </>
                        )}

                        <div>
                            {!readonly && canRemoveMember && (
                                <button
                                    className="btn btn-outline-danger"
                                    disabled={isRemoving}
                                    onClick={handleRemoveMember}
                                >
                                    Исключить
                                </button>
                            )}
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}