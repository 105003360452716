import {ReactComponent as Google} from '../../svg/socials/google.svg';
import {ReactComponent as Apple} from "../../svg/socials/apple.svg";
import {ReactComponent as Vkontakte} from '../../svg/socials/vkontakte.svg';
import {ReactComponent as Yandex} from '../../svg/socials/yandex.svg';
import {Link} from "react-router-dom";
import React from "react";
import {useAppContext} from "../../context/appContext";

export default function LinkedAccounts() {
    const {auth} = useAppContext()

    const accounts = auth.getUser().social

    const socials = [
        {
            key: 'apple',
            icon: <Apple/>,
            name: 'Apple',
        },
        {
            key: 'google',
            icon: <Google/>,
            name: 'Google',
        },
        {
            key: 'yandex',
            icon: <Yandex/>,
            name: 'Yandex',
        },
        {
            key: 'vkontakte',
            icon: <Vkontakte/>,
            name: 'VK',
        },
    ]

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                    <div className="mt-3 mt-md-5">
                        <h4>
                            <Link
                                to={'/profile'}
                                className="text-decoration-none"
                            >←️</Link>
                            &nbsp;Привязанные аккаунты
                        </h4>
                    </div>

                    <div className="list-group">
                        {socials.map(social => (
                            <div
                                key={social.key}
                                className="list-group-item d-flex justify-content-between align-items-center"
                            >
                                <div className="d-inline">
                                    {social.icon}
                                    <span className="ms-3">{social.name}</span>
                                </div>
                                {accounts[social.key]
                                    ? (
                                        <span className="badge bg-success">Привязан</span>
                                    )
                                    : (
                                        <span className="badge bg-secondary">Нет</span>
                                    )
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}