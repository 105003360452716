export default function FullPageLoader() {
    const sizeRem = 3;

    return (
        <div className="vh-100 w-100">
            <div className="d-flex justify-content-center align-items-center h-100">
                <div
                    className="spinner-border"
                    style={{
                        width: `${sizeRem}rem`,
                        height: `${sizeRem}rem`,
                    }}
                    role="status"
                >
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    )
}