import {Navigate, useParams} from 'react-router-dom';
import {useAppContext} from '../../context/appContext';
import {useEffect, useState} from 'react';
import FullPageLoader from '../Util/FullPageLoader';
import {clearRedirectData, getRedirectData} from '../../services/auth/redirectData';

export default function OAuth() {
    const {auth} = useAppContext()
    const {provider} = useParams()

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const [redirectData, setRedirectData] = useState(null)

    useEffect(() => {
        setRedirectData(getRedirectData())

        const token = auth.parseOAuth(provider, new URL(window.location.href))

        auth.authenticateSso(provider, token)
            .catch(e => {
                console.error(e);
                setError('Ошибка входа')
            })
            .finally(() => {
                setLoading(false)
            })
    }, []);

    if (loading) {
        return FullPageLoader();
    }

    if (error) {
        return <div>{error}</div>
    }

    if (redirectData !== null && redirectData.isValid()) {
        clearRedirectData()

        return <Navigate to={redirectData.path()}/>
    }

    return <Navigate to={'/'}/>
}