export default class Price {
    raw: Number;

    constructor(raw: Number) {
        this.raw = raw;
    }

    format(): String {
        const pennies = this.raw % 100;
        const int = Math.floor(this.raw / 100);
        const intStr = int.toString().split('.')[0];

        let str = ''

        for (let i = intStr.length - 1; i >= 0; i--) {
            if (i !== intStr.length - 1 && (intStr.length - i - 1) % 3 === 0) {
                str = '\u00A0' + str;
            }
            str = intStr[i] + str;
        }

        if (pennies > 0) {
            str += '.' + pennies;
        }

        return str
    }
}