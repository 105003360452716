import Invite from './Invite';
import {useAppContext} from '../../../context/appContext';
import CreatePhantom from './CreatePhantom';
import Avatar from '../../Avatar/Avatar';
import {useState} from 'react';
import MemberModal from './MemberModal';
import AddFriend from './AddFriend';

export default function Members({
                                    board,
                                    permissions,
                                    onRegenerate,
                                    onLeave,
                                    onRemoveMember,
                                    onCreatePhantom,
                                    onAddFriend,
                                }) {
    const {auth} = useAppContext()

    const [memberInModal, setMemberInModal] = useState(null)

    const phantomsAllowed = process.env.REACT_APP_TOGGLE_PHANTOMS === 'true' && auth.getUser().isPro()

    const existingPhantomNames = board.members
        .filter(m => m.type === 'phantom')
        .map(m => m.name())

    const handleOpenMemberModal = (member) => {
        setMemberInModal(member)
    }

    const handleCloseMemberModal = () => {
        setMemberInModal(null)
    }

    const canRemoveMember = () => {
        if (!memberInModal) {
            return false;
        }
        return permissions.removeMembers && memberInModal.user?.id !== board.creator.id
    }

    return (
        <>
            <MemberModal
                member={memberInModal}
                readonly={board.isArchived}
                isOpen={memberInModal !== null}
                onClose={handleCloseMemberModal}
                canRemoveMember={canRemoveMember(memberInModal)}
                onRemoveMember={() => onRemoveMember(memberInModal)}
            />

            <div className="row">
                <div className="col-12 col-md-8 order-2 order-md-1">
                    <ul className="list-group list-group-flush mb-3">
                        {board.members.map(member => (
                            <li
                                key={member.id}
                                className="list-group-item d-flex justify-content-between align-items-start"
                            >
                                <div
                                    className="ms-2 me-auto cursor-pointer"
                                    onClick={() => handleOpenMemberModal(member)}
                                >
                                    <Avatar className="d-inline-block me-2" emoji={member.avatar().emoji}
                                            color={member.avatar().color}/>
                                    {member.name()}
                                </div>
                                {member.user?.id === board.creator.id && (
                                    <span className="badge bg-primary rounded-pill">создатель</span>
                                )}
                            </li>
                        ))}
                    </ul>

                    <div>
                        {!board.isArchived && phantomsAllowed && (
                            <CreatePhantom
                                existingPhantomNames={existingPhantomNames}
                                onSubmit={onCreatePhantom}
                            />
                        )}

                        {!board.isArchived && (
                            <AddFriend
                                members={board.members.filter(m => m.type === 'user').map(m => m.user.id)}
                                onAddFriend={onAddFriend}
                            />
                        )}
                    </div>

                    {permissions.leave && (
                        <button
                            className="btn btn-outline-danger mt-5"
                            onClick={onLeave}
                        >
                            Покинуть доску
                        </button>
                    )}
                </div>
                {!board.isArchived && (
                    <div className="col-12 col-md-4 order-1 order-md-2">
                        <Invite
                            inviteUrl={board.inviteUrl}
                            canRegenerate={permissions.regenerateInviteLink}
                            onRegenerate={onRegenerate}
                            hasPhantoms={board.hasPhantoms()}
                        />
                    </div>
                )}
            </div>
        </>
    )
}