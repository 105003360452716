export default class OAuth {
    #baseUrl;
    #params;
    #parseResponse;

    constructor(baseUrl, params, parseResponse) {
        this.#baseUrl = baseUrl
        this.#params = params
        this.#parseResponse = parseResponse
    }

    getUrl() {
        const url = new URL(this.#baseUrl)
        for (const [key, value] of Object.entries(this.#params)) {
            url.searchParams.append(key, value)
        }
        return url.toString()
    }

    parseTokenFromUrl(url: URL): string {
        return this.#parseResponse(url)
    }
}