import {Outlet} from 'react-router-dom';
import React from 'react';
import Header from './Header';

export default function DefaultLayout() {
    return (
        <>
            <Header/>
            <div className={'container'}>
                <Outlet/>
            </div>
        </>
    )
}