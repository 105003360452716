import React from "react";
import {Link} from "react-router-dom";

export default function NotFound() {
    return (
        <div className="d-flex align-items-center vh-100">
            <div className="w-100 m-auto">
                <div className="d-flex">
                    {/*<img*/}
                    {/*    className="mb-4 mx-auto"*/}
                    {/*    src={'/icon.png'}*/}
                    {/*    alt={'Где Деньги'}*/}
                    {/*    width={72}*/}
                    {/*    height={72}*/}
                    {/*/>*/}
                    <h1 className="mx-auto">
                        😐
                    </h1>
                </div>
                <h1 className="h3 mb-3 text-center">
                    Такая страница не найдена
                </h1>
                <div className="d-flex">
                    <Link to="/" className="d-flex mx-auto text-decoration-none">
                        На главную
                    </Link>
                </div>
            </div>
        </div>
    )
}