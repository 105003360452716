import AddFriendModal from './AddFriendModal';
import {useStateBool} from '../../../services/util/useStateBool';

export default function AddFriend({members, onAddFriend}) {
    const [isModalOpened, openModal, closeModal] = useStateBool(false)

    return (
        <>
            <button
                className="btn btn-primary"
                onClick={openModal}
            >
                Добавить друга
            </button>

            <AddFriendModal
                members={members}
                isOpen={isModalOpened}
                onClose={closeModal}
                onAddFriend={onAddFriend}
            />
        </>
    )
}