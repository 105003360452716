import OAuth from './oauth';

export default class OAuthProvidersFactory {
    static google(): OAuth {
        return new OAuth('https://accounts.google.com/o/oauth2/v2/auth', {
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            redirect_uri: process.env.REACT_APP_URL + '/oauth/google',
            response_type: 'token',
            scope: 'openid profile email',
            include_granted_scopes: 'true',
        }, this.#fromHash('access_token'))
    }

    static apple(): OAuth {
        return new OAuth('https://appleid.apple.com/auth/authorize', {
            client_id: process.env.REACT_APP_APPLE_CLIENT_ID,
            redirect_uri: process.env.REACT_APP_URL + '/oauth/apple',
            response_type: 'code id_token',
            response_mode: 'fragment',
            // scope: 'name email',
        }, this.#fromHash('id_token'))
    }

    static vkontakte(): OAuth {
        return new OAuth('https://oauth.vk.com/authorize', {
            client_id: process.env.REACT_APP_VK_CLIENT_ID,
            redirect_uri: process.env.REACT_APP_URL + '/oauth/vkontakte',
            response_type: 'token',
            display: 'page',
            scope: 'email,offline',
        }, this.#fromHash('access_token'))
    }

    static yandex(): OAuth {
        return new OAuth('https://oauth.yandex.ru/authorize', {
            client_id: process.env.REACT_APP_YANDEX_CLIENT_ID,
            redirect_uri: process.env.REACT_APP_URL + '/oauth/yandex',
            response_type: 'token',
        }, this.#fromHash('access_token'))
    }

    static #fromHash(key: string) {
        return (url: URL) => {
            const params = new URLSearchParams(url.hash.slice(1))

            if (!params.has(key)) {
                throw new Error(`no ${key} provided`)
            }

            return params.get(key)
        }
    }
}