import React, {useEffect, useState} from 'react';
import {useAppContext} from '../../context/appContext';
import AvatarInput from '../Avatar/AvatarInput';
import Modal from '../Modal/Modal';
import BoardEmojis from '../../services/util/boardEmojis';
import InvalidFeedback from '../Form/InvalidFeedback';
import {useNavigate} from 'react-router-dom';

export default function CreateBoardModal({isOpen, onClose}) {
    const navigate = useNavigate()

    const {api, auth} = useAppContext()

    const [currencies, setCurrencies] = useState([])
    const [extraCurrenciesEnabled, setExtraCurrenciesEnabled] = useState(false)

    const [extraCurrencies, setExtraCurrencies] = useState(new Map())

    const showOptimizeDebtsCheckbox = auth.getUser().isDeveloper()

    const emptyForm = () => ({
        name: '',
        currencyId: currencies[0]?.id || null,
        emoji: BoardEmojis[Math.floor(Math.random() * BoardEmojis.length)],
        color: '#' + Math.floor(Math.random() * 16777215).toString(16),
        optimizeDebts: true,
    })

    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState(emptyForm())
    const [errors, setErrors] = useState({})

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (loading) {
            return
        }
        setLoading(true)

        try {
            const errors = validate()
            if (Object.keys(errors).length > 0) {
                setErrors(errors)
                return
            }

            const extraCurrenciesArray = []
            if (extraCurrenciesEnabled) {
                for (let [id, enabled] of extraCurrencies) {
                    if (enabled) {
                        extraCurrenciesArray.push(id)
                    }
                }
            }

            const board = await api.createBoard({
                name: form.name,
                currencyId: form.currencyId,
                avatar: {
                    emoji: form.emoji,
                    color: form.color.slice(1),
                },
                optimizeDebts: form.optimizeDebts,
                extraCurrencyIds: extraCurrenciesArray,
            })

            navigate('/boards/' + board.id)
        } finally {
            setLoading(false)
        }
    }

    const validate = () => {
        const errors = {}

        if (!form.name) {
            errors.name = 'Не введено название доски'
        }

        return errors
    }

    const setCurrency = currencyId => {
        setForm({...form, currencyId: currencyId})

        const newExtraCurrencies = new Map

        for (const currency of currencies) {
            if (currency.id != currencyId) {
                newExtraCurrencies.set(currency.id, extraCurrencies.get(currency.id) || false)
            }
        }

        setExtraCurrencies(newExtraCurrencies)
    }

    const toggleExtraCurrency = currencyId => {
        if (!canToggleExtraCurrency(currencyId)) {
            return
        }

        const newExtraCurrencies = new Map()

        for (let [id, enabled] of extraCurrencies) {
            newExtraCurrencies.set(id, enabled)
        }

        newExtraCurrencies.set(currencyId, !(newExtraCurrencies.get(currencyId) || false))

        setExtraCurrencies(newExtraCurrencies)
    }

    const canToggleExtraCurrency = (currencyId) => {
        if (auth.getUser().isPro()) {
            return true
        }


        let extraCurrenciesCount = 0

        for (let [id, isEnabled] of extraCurrencies) {
            if (id === currencyId && isEnabled) {
                return true
            }

            if (isEnabled) {
                extraCurrenciesCount++
            }
        }

        return extraCurrenciesCount < 1
    }

    useEffect(() => {
        api.getCurrenciesList()
            .then((currencies) => {
                setCurrencies(currencies)
                setForm({
                    ...form,
                    currencyId: currencies[0]?.id || null,
                })
            })
    }, []);

    return (
        <>
            {isOpen && (
                <Modal onClose={onClose}>
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Создание доски
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                        />
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="form-floating mb-3">
                                <input
                                    className={errors.name ? 'form-control is-invalid' : 'form-control'}
                                    id="name"
                                    placeholder="."
                                    value={form.name}
                                    onChange={(e) => setForm({...form, name: e.target.value})}
                                />
                                <label
                                    htmlFor="name"
                                    className="form-label"
                                >
                                    Название
                                </label>
                                <InvalidFeedback error={errors.name}/>
                            </div>
                            <div className="form-floating mb-3">
                                <select
                                    id="currencyId"
                                    className="form-select"
                                    aria-describedby="currencyIdHelp"
                                    value={form.currencyId}
                                    onChange={(e) => setCurrency(e.target.value)}
                                >
                                    {currencies.map((currency, index) => (
                                        <option
                                            key={index}
                                            value={currency.id}
                                        >
                                            {currency.flagEmoji} {currency.title} ({currency.symbol})
                                        </option>
                                    ))}
                                </select>
                                <label htmlFor="currencyId" className="form-label">
                                    Основная валюта
                                </label>
                                <div id="currencyIdHelp" className="form-text">
                                    Валюта по умолчанию. В этой валюте будут вычисляться долги.
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="extraCurrenciesToggle"
                                        checked={extraCurrenciesEnabled}
                                        onChange={(e) => setExtraCurrenciesEnabled(e.target.checked)}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="extraCurrenciesToggle"
                                    >
                                        Дополнительные валюты
                                    </label>
                                </div>

                                {extraCurrenciesEnabled && <>
                                    {currencies.filter(currency => currency.id != form.currencyId).map((currency) => (
                                        <div
                                            key={currency.id}
                                            className="form-check"
                                        >
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={extraCurrencies.get(currency.id) || false}
                                                id={'extraCurrency' + currency.id}
                                                onChange={e => toggleExtraCurrency(currency.id)}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor={'extraCurrency' + currency.id}
                                            >
                                                {currency.flagEmoji} {currency.title} ({currency.symbol})
                                            </label>
                                        </div>
                                    ))}
                                </>}
                            </div>
                            <div className="mb-3">
                                <AvatarInput
                                    emoji={form.emoji}
                                    color={form.color}
                                    setEmoji={v => setForm({...form, emoji: v})}
                                    setColor={v => setForm({...form, color: v})}
                                />
                            </div>
                            {showOptimizeDebtsCheckbox && <div className="form-check mb-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="optimizeDebts"
                                    checked={form.optimizeDebts}
                                    onChange={() => setForm({...form, optimizeDebts: !form.optimizeDebts})}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="optimizeDebts"
                                >
                                    Оптимизация долгов
                                </label>
                            </div>}
                            <button
                                type="submit"
                                className="btn btn-success"
                                disabled={loading}
                            >
                                Сохранить
                            </button>
                        </form>
                    </div>
                </Modal>
            )}
        </>
    )
}