import React, {useEffect, useState} from 'react';
import Modal from '../../Modal/Modal';
import {useAppContext} from '../../../context/appContext';
import {useStateBool} from '../../../services/util/useStateBool';
import AvatarPlaceholder from '../../Avatar/AvatarPlaceholder';
import Avatar from '../../Avatar/Avatar';

export default function AddFriendModal({members, isOpen, onClose, onAddFriend}) {
    const {api, toast} = useAppContext()

    const [friends, setFriends] = useState([])
    const [loading, startLoading, finishLoading] = useStateBool(true)

    const handleClose = () => {
        onClose()
    }

    useEffect(() => {
        if (isOpen) {
            startLoading()
            api.getFriends({canInvite: 1})
                .then(friends => {
                    setFriends(friends.filter(friend => !members.includes(friend.user.id)))
                })
                .finally(() => {
                    finishLoading()
                })
        } else {
            startLoading()
        }
    }, [isOpen]);

    const handleSelectFriend = async (friend) => {
        try {
            await onAddFriend(friend)
            handleClose()
        } catch (e) {
            if (e?.response?.status === 400) {
                toast.error(e.response.data.error)
            } else {
                throw e
            }
        }
    }

    return (
        <>
            {isOpen && (
                <Modal onClose={handleClose}>
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Выберите друга
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        />
                    </div>
                    <div className="modal-body">
                        <div className="list-group list-group-flush">
                            {loading
                                ? <FriendsPlaceholder/>
                                : (friends.length === 0
                                        ? <EmptyFriends/>
                                        : (friends.map(friend => (
                                                <div
                                                    key={friend.user.id}
                                                    className="list-group-item placeholder-glow cursor-pointer"
                                                    onClick={() => handleSelectFriend(friend)}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <div className="avatar avatar-sm">
                                                            <Avatar emoji={friend.user.avatar.emoji}
                                                                    color={friend.user.avatar.color}/>
                                                        </div>
                                                        <span className="ms-3">{friend.user.name}</span>
                                                    </div>
                                                </div>
                                            ))
                                        )
                                )}
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}

function FriendsPlaceholder() {
    return (
        <>
            {[...Array(5).keys()].map(i => (
                <div key={i} className="list-group-item placeholder-glow">
                    <div className="d-flex align-items-center">
                        <div className="avatar avatar-sm">
                            <AvatarPlaceholder/>
                        </div>
                        <span className="placeholder col-7 ms-3"/>
                    </div>
                </div>
            ))}
        </>
    )
}

function EmptyFriends() {
    return (
        <div className="list-group-item">
            Не найдено друзей, которых можно добавить
        </div>
    )
}