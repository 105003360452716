import {Link} from 'react-router-dom';

export default function Profile() {
    const links = [
        {
            to: '/profile/edit',
            text: 'Редактировать профиль',
        },
        {
            to: '/profile/accounts',
            text: 'Привязанные аккаунты',
        },
        {
            to: '/profile/notifications',
            text: 'Настройки уведомлений',
        },
        {
            to: '/profile/privacy',
            text: 'Конфиденциальность',
        },
        // {
        //     to: '/profile/sessions',
        //     text: 'Активные сессии',
        // },
        // {
        //     to: '/support',
        //     text: 'Поддержка',
        // },
        {
            href: 'https://gde.money/faq',
            text: 'FAQ',
        },
        {
            to: '/profile/delete',
            text: 'Удаление аккаунта',
        },
    ];

    return (
        <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                <div className="mt-3 mt-md-5">
                    <h4>
                        Профиль
                    </h4>
                </div>
                <div className="list-group">
                    {links.map(link => link.href
                        ? (
                            <a
                                key={link.href}
                                href={link.href}
                                target="_blank"
                                rel="noreferrer"
                                className="list-group-item list-group-item-action"
                            >
                                {link.text}
                            </a>
                        )
                        : (
                            <Link
                                key={link.to}
                                to={link.to}
                                className="list-group-item list-group-item-action"
                            >
                                {link.text}
                            </Link>
                        ),
                    )}
                </div>
            </div>
        </div>
    )
}