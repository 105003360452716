import Avatar from './Avatar';
import boardEmojis from '../../services/util/boardEmojis';

export default function AvatarInput({emoji, color, setEmoji, setColor}) {
    return (
        <>
            <div className="d-flex">
                <Avatar className="my-auto" emoji={emoji} color={color} size="large"/>

                <div className="ms-5 mt-3">
                    <div className="row g-3">
                        <div className="col-auto mt-0">
                            <label htmlFor="color" className="col-form-label">
                                Цвет
                            </label>
                        </div>
                        <div className="col-auto ms-auto mt-0">
                            <input
                                type="color"
                                id="color"
                                className="form-control form-control-color"
                                style={{width: '3.5rem'}}
                                value={color}
                                onChange={(e) => setColor(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="row mt-1">
                        <div className="col-auto mt-0">
                            <label htmlFor="emoji" className="col-form-label">
                                Эмодзи
                            </label>
                        </div>
                        <div className="col-auto mt-0">
                            <div className="dropup-center dropup">
                                <button
                                    className="btn btn-outline-form dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {emoji}
                                </button>
                                <ul className="dropdown-menu emojis">
                                    <li className="dropdown-header">Выберите эмодзи</li>
                                    {boardEmojis.map((be, i) => (
                                        <li
                                            key={i}
                                            className="dropdown-item p-0 mb-1 cursor-pointer text-center"
                                            onClick={() => setEmoji(be)}
                                        >
                                            {be}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}