import {useAppContext} from "../../context/appContext";
import {Link, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import InvalidFeedback from "../Form/InvalidFeedback";
import AvatarInput from "../Avatar/AvatarInput";
import AuthUser from '../../services/models/AuthUser';

export default function EditProfile() {
    const {api, auth} = useAppContext()
    const navigate = useNavigate()

    const emptyForm = () => ({
        name: auth.getUser().name,
        email: auth.getUser().email,
        phone: auth.getUser().phone?.pretty || '',
        emoji: auth.getUser().avatar.emoji,
        color: '#' + auth.getUser().avatar.color,
    })

    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState(emptyForm())
    const [errors, setErrors] = useState({})

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (loading) {
            return
        }
        setLoading(true)

        try {
            const errors = validate()
            if (Object.keys(errors).length > 0) {
                setErrors(errors)
                return
            }

            const newProfile = await api.updateProfile({
                name: form.name,
                email: form.email,
                phone: convertPhone(form.phone),
                avatar: {
                    emoji: form.emoji,
                    color: form.color.slice(1),
                },
            })
            auth.setUser(new AuthUser(newProfile))

            navigate('/')
        } finally {
            setLoading(false)
        }
    }

    const validate = () => {
        const errors = {}

        if (!form.name) {
            errors.name = 'Введите имя'
        }
        if (!form.email) {
            errors.email = 'Введите email'
        }
        const convertedPhone = convertPhone(form.phone)
        if (!!convertedPhone && convertedPhone.length !== 11) {
            errors.phone = 'Неверный формат телефона'
        }

        return errors
    }

    const convertPhone = phone => {
        if (!phone) {
            return ''
        }

        let newPhone = phone.replace(/\D/g, '')

        if (newPhone.length === 11 && newPhone[0] === '8') {
            newPhone = '7' + newPhone.slice(1)
        }

        return newPhone
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                    <div className="mt-3 mt-md-5">
                        <h4>
                            <Link
                                to={'/profile'}
                                className="text-decoration-none"
                            >←️</Link>
                            &nbsp;Редактирование профиля
                        </h4>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="form-floating mb-3">
                            <input
                                className={errors.name ? 'form-control is-invalid' : 'form-control'}
                                id="name"
                                placeholder="."
                                value={form.name}
                                onChange={(e) => setForm({...form, name: e.target.value})}
                            />
                            <label
                                htmlFor="name"
                                className="form-label"
                            >
                                Имя
                            </label>
                            <InvalidFeedback error={errors.name}/>
                        </div>

                        <div className="form-floating mb-3">
                            <input
                                className={errors.email ? 'form-control is-invalid' : 'form-control'}
                                id="email"
                                placeholder="."
                                value={form.email}
                                onChange={(e) => setForm({...form, email: e.target.value})}
                            />
                            <label
                                htmlFor="email"
                                className="form-label"
                            >
                                Email
                            </label>
                            <InvalidFeedback error={errors.email}/>
                        </div>

                        <div className="form-floating mb-3">
                            <input
                                className={errors.phone ? 'form-control is-invalid' : 'form-control'}
                                id="phone"
                                placeholder="."
                                value={form.phone}
                                onChange={(e) => setForm({...form, phone: e.target.value})}
                            />
                            <label
                                htmlFor="phone"
                                className="form-label"
                            >
                                Телефон
                            </label>
                            <InvalidFeedback error={errors.phone}/>
                        </div>

                        <div className="mb-3">
                            <AvatarInput
                                emoji={form.emoji}
                                color={form.color}
                                setEmoji={v => setForm({...form, emoji: v})}
                                setColor={v => setForm({...form, color: v})}
                            />
                        </div>


                        <button
                            type="submit"
                            className="btn btn-success"
                            disabled={loading}
                        >
                            Сохранить
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}