import Modal from '../../Modal/Modal';
import React from 'react';
import Avatar from '../../Avatar/Avatar';

export default function PaymentModal({data, isOpen, onClose, onDelete}) {
    if (!data || !isOpen) {
        return null
    }

    const {payment, permissions, readonly} = data

    const isExpense = payment.type === 'expense';

    const isMulticurrency = payment.currency.id !== data.mainCurrency.id;

    const title = isExpense ? payment.title : (payment.isMercy ? 'Аннулирование долга' : 'Перевод');

    return (
        <>
            {isOpen && (
                <Modal onClose={onClose}>
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {title}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                        />
                    </div>
                    <div className="modal-body">
                        <div className="h3 mb-3">
                            {payment?.amount.format()}&nbsp;{payment?.currency.symbol}
                            {isMulticurrency && (
                                <span className="h6 text-secondary"> {payment?.amountMainCurrency.format()}&nbsp;{data?.mainCurrency.symbol}</span>
                            )}
                        </div>
                        {isExpense
                            ? (
                                <>
                                    <div className="d-flex mb-3">
                                        <Avatar
                                            emoji={payment?.payer.avatar().emoji}
                                            color={payment?.payer.avatar().color}
                                            className="d-inline me-2"
                                        />
                                        {payment?.payer.name()} оплатил(а)
                                    </div>
                                    <ul className="list-group mb-3">
                                        {payment.members.map((member, i) => (
                                            <li
                                                key={i}
                                                className="list-group-item d-flex justify-content-between align-items-center"
                                            >
                                                <div className="d-flex">
                                                    <Avatar
                                                        emoji={member.boardMember.avatar().emoji}
                                                        color={member.boardMember.avatar().color}
                                                        className="d-inline me-2"
                                                    />
                                                    {member.boardMember.name()}
                                                </div>
                                                <span>{member.amount.format()} {payment.currency.symbol}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )
                            : (
                                <dl>
                                    <dd className="d-flex">
                                        <Avatar
                                            emoji={payment?.payer.avatar().emoji}
                                            color={payment?.payer.avatar().color}
                                            className="d-inline me-2"
                                        />
                                        {payment?.payer.name()}
                                    </dd>
                                    <dd className="text-secondary">↓ (Cовершил(а) денежный перевод)</dd>
                                    <dd className="d-flex">
                                        <Avatar
                                            emoji={payment?.payee.avatar().emoji}
                                            color={payment?.payee.avatar().color}
                                            className="d-inline me-2"
                                        />
                                        {payment?.payee.name()}
                                    </dd>
                                </dl>
                            )
                        }

                        <p className="text-secondary">
                            {new Date(payment.paidAt).toLocaleDateString('ru-RU', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })}
                        </p>

                        {!readonly && permissions.delete && (
                            <button
                                className="btn btn-outline-danger mt-3"
                                onClick={onDelete}
                            >
                                Удалить
                            </button>
                        )}
                    </div>
                </Modal>
            )}
        </>
    )
}