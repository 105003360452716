import React, {useReducer, useState} from 'react';
import Api from '../services/api';
import Auth from '../services/auth';
import {ToastContainer, toastReducer} from '../components/Util/Toast';
import OAuthProvidersFactory from '../services/auth/providers';

const AppContext = React.createContext(null);

export const AppContextProvider = ({children, ...props}) => {
    const [state, dispatch] = useReducer(toastReducer, {toasts: []})

    const addToast = (type, message) => {
        const id = Math.floor(Math.random() * 10000000);
        dispatch({type: 'ADD_TOAST', payload: {id, message, type}});
    };

    const toastInfo = (message) => {
        addToast('info', message);
    };

    const toastError = (message) => {
        addToast('error', message);
    };

    const toastRemove = (id) => {
        dispatch({type: 'DELETE_TOAST', payload: id});
    };

    const context = useCreateAppContext({
        ...props,
        toast: {
            info: toastInfo,
            remove: toastRemove,
            error: toastError,
        },
    })

    return (
        <AppContext.Provider value={context}>
            {children}
            <ToastContainer toasts={state.toasts}/>
        </AppContext.Provider>
    )
}

export function useAppContext() {
    const context = React.useContext(AppContext)
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider')
    }
    return context
}

export const useCreateAppContext = (props) => {
    const [api] = useState(new Api(process.env.REACT_APP_API_URL));
    const [auth] = useState(new Auth(api))

    auth.addOAuthProvider('google', OAuthProvidersFactory.google())
    auth.addOAuthProvider('apple', OAuthProvidersFactory.apple())
    auth.addOAuthProvider('vkontakte', OAuthProvidersFactory.vkontakte())
    auth.addOAuthProvider('yandex', OAuthProvidersFactory.yandex())

    return {
        api,
        auth,
        toast: props.toast,
        config: {
            showCredentialsButton: process.env.REACT_APP_SHOW_LOGIN_CREDENTIALS === 'true',
        },
    }
}