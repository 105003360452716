export default class BoardMember {
    id: Number;
    type: String;
    user: ?Object;
    phantom: ?Object;

    isUser(): Boolean {
        return this.type === 'user';
    }

    isPhantom(): Boolean {
        return this.type === 'phantom';
    }

    name(): String {
        return this[this.type].name;
    }

    avatar(): Object {
        return this[this.type].avatar;
    }
}