import React, {useState} from 'react';
import Modal from '../../Modal/Modal';
import InvalidFeedback from '../../Form/InvalidFeedback';
import {useStateBool} from '../../../services/util/useStateBool';

export default function CreatePhantomModal({existingPhantomNames, isOpen, onClose, onSubmit}) {
    const emptyForm = () => ({
        name: '',
    })

    const [form, setForm] = useState(emptyForm())
    const [errors, setErrors] = useState({})
    const [loading, startLoading, finishLoading] = useStateBool(false)

    const clearForm = () => {
        setForm(emptyForm())
        setErrors({})
    }

    const handleClose = () => {
        onClose();
        clearForm()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (loading) {
            return
        }
        if (!validate()) {
            return
        }
        startLoading()

        try {
            await onSubmit(form)
            handleClose()
        } catch (e) {
            console.log(e)
        } finally {
            finishLoading()
        }
    }

    const validate = () => {
        const newErrors = {}

        if (!form.name) {
            newErrors.name = 'Введите имя'
        } else if (existingPhantomNames.includes(form.name)) {
            newErrors.name = 'Имя уже занято'
        }

        setErrors(newErrors)

        return Object.keys(newErrors).length === 0
    }

    return (
        <>
            {isOpen && (
                <Modal onClose={handleClose}>
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Добавление фантома
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        />
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className={errors.name ? 'form-control is-invalid' : 'form-control'}
                                    id="name"
                                    placeholder="."
                                    value={form.name}
                                    autoFocus={true}
                                    onChange={e => setForm({...form, name: e.target.value})}
                                />
                                <label htmlFor="name" className="form-label">
                                    Имя
                                </label>
                                <InvalidFeedback error={errors.name}/>
                            </div>

                            <button
                                type="submit"
                                className="btn btn-success"
                                disabled={loading}
                            >
                                Сохранить
                            </button>
                        </form>
                    </div>
                </Modal>
            )}
        </>
    )
}