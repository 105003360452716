// custom imports
import './bootstrap';
import './App.scss';
// react
import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
// contexts
import {AppContextProvider} from './context/appContext';
// auth
import RequireAuth from './components/Auth/RequireAuth';
import InitAuth from './components/Auth/InitAuth';
// layouts
import DefaultLayout from './components/Layouts/DefaultLayout';
// pages
import Dashboard from './components/Dashboard/Dashboard';
import Login from './components/Auth/Login';
import Profile from './components/Profile/Profile';
import BoardPage from './components/Boards/BoardPage';
import {getThemeFromLocalStorage, setThemeToDocument, setThemeToLocalStorage} from './components/Theme/theme';
import NotificationSettings from './components/Profile/NotificationSettings';
import DeleteProfile from './components/Profile/DeleteProfile';
import NotFound from './components/NotFound';
import Invite from './components/Invite';
import EditProfile from './components/Profile/EditProfile';
import LinkedAccounts from './components/Profile/LinkedAccounts';
import FastLogin from './components/Auth/FastLogin';
import OAuth from './components/Auth/OAuth';
import PrivacySettings from './components/Profile/PrivacySettings';

function App() {
    useEffect(() => {
        const theme = getThemeFromLocalStorage();

        if (theme) {
            setThemeToDocument(theme)
            setThemeToLocalStorage(theme)
        }
    }, []);

    return (
        <div className="app">
            <AppContextProvider>
                <BrowserRouter>
                    <Routes>
                        <Route element={<InitAuth/>}>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/oauth/:provider" element={<OAuth/>}/>
                            <Route path="/fast-login" element={<FastLogin/>}/>
                            <Route element={<RequireAuth/>}>
                                <Route path="/invite/:token" element={<Invite/>}/>
                                <Route element={<DefaultLayout/>}>
                                    <Route path="/" element={<Dashboard/>}/>
                                    <Route path="/profile" element={<Profile/>}/>
                                    <Route path="/profile/edit" element={<EditProfile/>}/>
                                    <Route path="/profile/notifications" element={<NotificationSettings/>}/>
                                    <Route path="/profile/privacy" element={<PrivacySettings/>}/>
                                    <Route path="/profile/accounts" element={<LinkedAccounts/>}/>
                                    <Route path="/profile/delete" element={<DeleteProfile/>}/>
                                    <Route path="/boards/:boardId/*" element={<BoardPage/>}/>
                                </Route>
                            </Route>
                        </Route>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </BrowserRouter>
            </AppContextProvider>
        </div>
    );
}

export default App;
