import {useAppContext} from '../../../context/appContext';
import React, {Fragment, useEffect, useState} from 'react';
import CreatePaymentModal from './CreatePaymentModal';
import PaymentModal from './PaymentModal';
import {useStateBool} from '../../../services/util/useStateBool';
import {formatDateString} from '../../../services/util/dateFormatting';
import Avatar from '../../Avatar/Avatar';
import PropTypes from 'prop-types';

export default function Payments({board}) {
    const {api, toast} = useAppContext()

    const [initialLoading, _, finishInitialLoading] = useStateBool(true)
    const [loading, setLoading] = useState(false)
    const [arrPayments, setArrPayments] = useState([])
    const [lastLoadedPage, setLastLoadedPage] = useState(0)
    const [hasMore, setHasMore] = useState(false)
    const [openedPaymentData, setOpenedPaymentData] = useState(null)
    const [isOpenedCreatePaymentModal, setIsOpenedCreatePaymentModal] = useState(false)

    const addPayments = (arr, existing) => {
        // init new map
        const map = new Map()

        // add existing payments to map
        existing.forEach(([date, data]) => {
            if (!map.has(date)) {
                map.set(date, [])
            }
            map.get(date).push(...data)
        })

        // add new payments to map
        for (const payment of arr) {
            const formattedDate = formatDateString(payment.paidAt)

            if (!map.has(formattedDate)) {
                map.set(formattedDate, [])
            }

            map.get(formattedDate).push(payment)
        }

        setArrPayments([...map.entries()])
    }

    useEffect(() => {
        loadFirstPage()
            .then(() => {
                finishInitialLoading()
            })
    }, [])

    const loadFirstPage = async () => {
        const {payments, meta} = await api.getBoardPayments(board.id, 1)
        addPayments(payments, [])
        setLastLoadedPage(meta.currentPage)
        setHasMore(meta.currentPage < meta.lastPage)
    }

    const handleLoadMore = async () => {
        setLoading(true)

        try {
            const {payments: newPayments, meta} = await api.getBoardPayments(board.id, lastLoadedPage + 1)
            addPayments(newPayments, arrPayments)
            setLastLoadedPage(meta.currentPage)
            setHasMore(meta.currentPage < meta.lastPage)
        } finally {
            setLoading(false)
        }
    }

    const handleOpenPayment = async (paymentId) => {
        const {payment, permissions} = await api.getBoardPayment(board.id, paymentId)

        setOpenedPaymentData({
            mainCurrency: board.currency,
            payment,
            permissions,
            readonly: board.isArchived,
        })
    }

    const handleCreatePayment = async (type, data) => {
        try {
            if (type === 'expense') {
                await api.createBoardExpense(board.id, data)
                toast.info('Трата добавлена')
            } else {
                await api.createBoardTransfer(board.id, data)
                toast.info('Перевод добавлен')
            }

            loadFirstPage()
        } catch (e) {
            throw e
        }
    }

    const handleDelete = async (payment) => {
        if (!payment) {
            return
        }
        if (!window.confirm('Вы уверены, что хотите удалить этот платеж?')) {
            return
        }
        await api.deleteBoardPayment(board.id, payment.id)
        setOpenedPaymentData(false)
        await loadFirstPage()
    }

    if (initialLoading) {
        return <PaymentsPlaceholder readonly={board.isArchived}/>
    }

    return (
        <>
            <PaymentModal
                data={openedPaymentData}
                isOpen={!!openedPaymentData}
                onClose={() => setOpenedPaymentData(null)}
                onDelete={() => handleDelete(openedPaymentData.payment)}
            />

            {!board.isArchived && (
                <>
                    <CreatePaymentModal
                        data={{
                            boardId: board.id,
                            mainCurrencyId: board.currency.id,
                            currencies: board.allowedCurrencies,
                            members: board.members,
                        }}
                        isOpen={isOpenedCreatePaymentModal}
                        onClose={() => setIsOpenedCreatePaymentModal(false)}
                        onSubmit={handleCreatePayment}
                    />

                    <button
                        className="btn btn-outline-primary mb-3"
                        onClick={() => setIsOpenedCreatePaymentModal(true)}
                    >
                        Добавить платеж
                    </button>
                </>
            )}

            <div className="list-group list-group-flush">
                {arrPayments.length === 0
                    ? <EmptyPayments/>
                    : arrPayments.map(([date, paymentsForDate]) => (
                        <PaymentsForDate
                            key={date}
                            date={date}
                            payments={paymentsForDate}
                            onOpen={handleOpenPayment}
                        />
                    ))
                }
            </div>

            {hasMore && (
                <div className="d-flex">
                    <button
                        className="btn btn-outline-primary mx-auto mt-3"
                        disabled={loading}
                        onClick={handleLoadMore}
                    >
                        Загрузить еще
                    </button>
                </div>
            )}
        </>
    )
}

function PaymentsForDate({date, payments, onOpen}) {
    return (
        <>
            <div className="list-group-item px-0">
                <h5 className="mt-2">{date}</h5>
            </div>
            {payments.map(payment => (
                <PaymentRow
                    key={payment.id}
                    payment={payment}
                    onOpen={onOpen}
                />
            ))}
        </>
    )
}

function PaymentRow({payment, onOpen}) {
    const title = payment.type === 'expense'
        ? payment.title
        : (payment.isMercy ? 'Аннулирование долга' : 'Перевод')

    const subtitle = payment.type === 'expense'
        ? payment.payer.name()
        : (payment.payer.name() + ' → ' + payment.payee.name())

    return (
        <div
            className="list-group-item cursor-pointer d-flex px-0"
            onClick={() => onOpen(payment.id)}
        >
            {!!payment.avatar
                ? <Avatar
                    className="my-auto"
                    emoji={payment.avatar.emoji}
                    color={payment.avatar.color}
                    size="medium"
                />
                : <PaymentAvatar type={payment.avatarType()}/>
            }
            <div className="d-flex ms-3 w-100 justify-content-between">
                <div>
                    <p className="h6 mb-0">
                        {title}
                    </p>
                    <p className="mb-1 text-secondary">
                        {subtitle}
                    </p>
                </div>

                <div className="h6">{payment.amount.format()}&nbsp;{payment.currency.symbol}</div>
            </div>

        </div>
    )
}

function PaymentAvatar({type}) {
    switch (type) {
        case 'expense':
            return <Avatar className="my-auto" emoji="🍿" color="#887525" size="medium"/>
        case 'transfer':
            return <Avatar className="my-auto" emoji="💸" color="#467C3E" size="medium"/>
        case 'mercy':
            return <Avatar className="my-auto" emoji="👼" color="#9E251A" size="medium"/>
        default:
            throw new Error('unknown type passed')
    }
}

PaymentAvatar.propTypes = {
    type: PropTypes.oneOf(['expense', 'transfer', 'mercy']).isRequired,
}

function PaymentsPlaceholder({readonly}) {
    return (
        <>
            {!readonly && (
                <button
                    className="btn btn-outline-primary mb-3 disabled placeholder"
                >
                    Добавить платеж
                </button>
            )}

            <div className="list-group list-group-flush px-0">
                {[...Array(5).keys()].map(i => (
                    <div
                        key={i}
                        className="list-group-item placeholder-glow"
                    >
                        <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-0">
                                <span className="placeholder rounded-1" style={{width: '180px'}}/>
                            </h5>
                            <span className="placeholder fw-bold rounded-1" style={{width: '100px'}}/>
                        </div>
                        <p className="mb-1">
                            <span className="placeholder rounded-1" style={{width: '170px'}}/>
                        </p>
                    </div>
                ))}
            </div>
        </>
    )
}

function EmptyPayments() {
    return (
        <div className="list-group-item">
            <span>Платежей пока нет</span>
        </div>
    )
}