import {Navigate, useSearchParams} from "react-router-dom";
import {useAppContext} from "../../context/appContext";
import {useEffect, useState} from "react";
import FullPageLoader from "../Util/FullPageLoader";

export default function FastLogin() {
    const {auth} = useAppContext()
    const [searchParams] = useSearchParams()
    const [loading, setLoading] = useState(true)

    const param = 'accessToken'

    const accessToken = searchParams.get(param)

    useEffect(() => {
        if (accessToken) {
            auth.setTokens(accessToken, '').then(() => {
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, []);

    if (loading) {
        return <FullPageLoader/>
    }

    return (
        <Navigate to="/"/>
    )
}