export class RedirectData {
    #path: string;
    #createdAt: Date;

    static fromJson(jsonString) {
        const json = JSON.parse(jsonString)
        return new RedirectData(json.path, new Date(json.createdAt))
    }

    constructor(path: string, createdAt: Date) {
        this.#path = path
        this.#createdAt = createdAt
    }

    isValid() {
        // valid for 30 minutes
        return this.#createdAt.getTime() + 1000 * 30 > new Date().getTime()
    }

    path() {
        return this.#path
    }

    toJson() {
        return JSON.stringify({
            path: this.#path,
            createdAt: this.#createdAt
        })
    }
}

const lsKey = 'authRedirectData'

export function setRedirectData(path: string) {
    const data = new RedirectData(path, new Date())
    localStorage.setItem(lsKey, data.toJson())
}

export function getRedirectData() {
    const data = localStorage.getItem(lsKey)

    if (!data) {
        console.log('no redirect data')
        return null
    }

    return RedirectData.fromJson(data)
}

export function clearRedirectData() {
    localStorage.removeItem(lsKey)
}