export default class SubscriptionFeatures {
    multicurrency: Boolean;
    reminders: Boolean;
    boardsLimit: Number;
    membersLimit: Number;
    phantoms: Boolean;
    regularExpenses: Boolean;
    paymentTags: Boolean;

    constructor(data: Object) {
        this.multicurrency = data.multicurrency ?? false;
        this.reminders = data.reminders ?? false;
        this.boardsLimit = data.boardsLimit ?? 5;
        this.membersLimit = data.membersLimit ?? 5;
        this.phantoms = data.phantoms ?? false;
        this.regularExpenses = data.regularExpenses ?? false;
        this.paymentTags = data.paymentTags ?? false;
    }
}