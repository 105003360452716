import {useAppContext} from '../../context/appContext';
import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {hexToRgba} from '../../services/util/hexToRgba';
import {Tooltip} from 'bootstrap';
import {useStateBool} from '../../services/util/useStateBool';

export default function BoardsList({archivedFilter}) {
    const {api} = useAppContext()

    const [initialLoading, startInitialLoading, finishInitialLoading] = useStateBool(true)
    const [loading, startLoading, finishLoading] = useStateBool(false)
    const [lastLoadedPage, setLastLoadedPage] = useState(0)
    const [hasMore, setHasMore] = useState(false)
    const [boards, setBoards] = useState([])

    const filters = {
        archived: archivedFilter === '0' ? '' : 'only',
    }

    const handleToggleFavorite = async (board) => {
        if (board.isFavorite) {
            await api.removeBoardFromFavorites(board.id)
            setBoards(boards.map(b => b.id === board.id ? {...b, isFavorite: false} : b))
        } else {
            await api.addBoardToFavorites(board.id)
            setBoards(boards.map(b => b.id === board.id ? {...b, isFavorite: true} : b))
        }
    }

    const loadFirstPage = async () => {
        const {boards, meta} = await api.getBoardsList(1, filters)
        setBoards(boards)
        setLastLoadedPage(meta.currentPage)
        setHasMore(meta.currentPage < meta.lastPage)
    }

    const loadNextPage = async () => {
        if (loading) {
            return
        }
        startLoading()

        try {
            const {boards: newBoards, meta} = await api.getBoardsList(lastLoadedPage + 1, filters)
            setBoards([...boards, ...newBoards])
            setLastLoadedPage(meta.currentPage)
            setHasMore(meta.currentPage < meta.lastPage)
        } finally {
            finishLoading()
        }
    }

    useEffect(() => {
        loadFirstPage()
            .then(() => {
                finishInitialLoading()
            })
    }, []);

    useEffect(() => {
        startInitialLoading()
        loadFirstPage().then(() => {
            finishInitialLoading()
        })
    }, [archivedFilter])

    if (initialLoading) {
        return (
            <Row>
                {[...Array(4).keys()].map(i => (
                    <BoardCardPlaceholder key={i}/>
                ))}
            </Row>
        )
    }

    if (boards.length === 0) {
        return (
            <Row>
                <Col>
                    {archivedFilter === '1' && (
                        <div
                            className="alert alert-warning d-flex"
                            style={{
                                transform: 'rotate(0deg)',
                                minHeight: '110px',
                            }}
                        >
                            <div className="my-auto">
                                <i className="bi bi-exclamation-triangle-fill"></i> Нет архивированных досок
                            </div>
                        </div>
                    )}
                    {archivedFilter === '0' && (
                        <div
                            className="alert alert-info d-flex"
                            style={{
                                transform: 'rotate(0deg)',
                                minHeight: '110px',
                            }}
                        >
                            У вас еще нет ни одной доски. Нажмите на кнопку "Создать" чтобы начать.
                        </div>
                    )}
                </Col>
            </Row>
        )
    }

    return (
        <Row>
            {boards.map(board => (
                <BoardCard
                    key={board.id}
                    board={board}
                    onToggleFavorite={() => handleToggleFavorite(board)}
                />
            ))}
            {hasMore && (
                <div className="d-flex">
                    <button
                        className="btn btn-outline-primary mx-auto mt-3"
                        disabled={loading}
                        onClick={loadNextPage}
                    >
                        Загрузить еще
                    </button>
                </div>
            )}
        </Row>
    )
}

function Row({children}) {
    return (
        <div className="row gy-2 mb-3 gx-2">
            {children}
        </div>
    )
}

function Col({children}) {
    return (
        <div className="col-6 col-md-4 col-lg-3">
            {children}
        </div>
    )
}

function BoardCard({board, onToggleFavorite}) {
    return (
        <Col>
            <div
                className="card board border-0 bg-gradient text-white rounded-3"
                style={{
                    backgroundColor: hexToRgba(board.avatar.color),
                }}
            >
                <div
                    className="card-body p-1 rounded-3"
                    style={{
                        transform: 'rotate(0deg)',
                        minHeight: '110px',
                    }}
                >
                    <div className="vertically-centered">
                        <div>
                            {board.name}
                        </div>
                    </div>
                    <div
                        className="position-absolute start-0 end-0 top-0 bottom-0 text-center z-n1"
                        style={{
                            fontSize: '50px',
                            lineHeight: '110px',
                            opacity: '40%',
                        }}
                    >
                        {board.avatar.emoji}
                    </div>
                    <div className="position-absolute top-0 end-0 pe-1 pt-1 z-2">
                        <BoardFavoriteToggle
                            isFavorite={board.isFavorite}
                            onToggle={onToggleFavorite}
                        />
                    </div>
                    <Link
                        to={`/boards/${board.id}`}
                        className="stretched-link text-decoration-none"
                    ></Link>
                </div>
            </div>
        </Col>
    )
}

function BoardCardPlaceholder() {
    return (
        <Col>
            <div
                className="card border-0 placeholder-glow"
                style={{
                    minHeight: '110px',
                }}
            >
                <span className="placeholder rounded-3 col-12 position-absolute top-0 bottom-0 start-0 end-0"/>
            </div>
        </Col>
    )
}

function BoardFavoriteToggle({isFavorite, onToggle}) {
    const tooltipRef = useRef()

    useEffect(() => {
        const tooltip = new Tooltip(tooltipRef.current, {
            title: isFavorite
                ? 'Убрать из избранного'
                : 'Добавить в избранное',
            placement: 'bottom',
            trigger: 'hover',
        })

        return () => {
            tooltip.dispose()
        }
    }, []);

    return (
        <button
            className="btn btn-sm btn-link z-2"
            ref={tooltipRef}
            onClick={onToggle}
        >
            {isFavorite
                ? <i className="bi bi-star-fill text-primary"></i>
                : <i className="bi bi-star text-secondary"></i>}
        </button>
    )
}