import PropTypes from 'prop-types';
import {hexToRgba} from '../../services/util/hexToRgba';

export default function Avatar({emoji, color, size, className}) {
    color = hexToRgba(color);

    let circleSize = 24;
    let fontSizeRem = 0.85;

    // eslint-disable-next-line default-case
    switch (size) {
        case 'medium':
            circleSize = 36;
            fontSizeRem = 1.35;
            break;
        case 'large':
            circleSize = 60;
            fontSizeRem = 2.15;
            break;
        case 'xlarge':
            circleSize = 120;
            fontSizeRem = 4.5;
            break;
    }

    let borderRadius = circleSize / 2;

    return (
        <div
            className={'text-center ' + className}
            style={{
                height: `${circleSize}px`,
                width: `${circleSize}px`,
                borderRadius: `${borderRadius}px`,
                backgroundColor: color,
                lineHeight: `${circleSize}px`,
                fontSize: `${fontSizeRem}rem`,
            }}
        >
            {emoji}
        </div>
    )
}

Avatar.propTypes = {
    emoji: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['medium', 'large', 'xlarge']),
    className: PropTypes.string,
}