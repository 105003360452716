import {Link, useNavigate} from "react-router-dom";
import {useAppContext} from "../../context/appContext";

export default function DeleteProfile() {
    const {api, auth} = useAppContext()
    const navigate = useNavigate()

    const handleDeleteProfile = async () => {
        if (!window.confirm('Это действие необратимо. Вы уверены, что хотите удалить аккаунт?')) {
            return
        }

        await api.deleteProfile()
        await auth.logout()
        navigate('/login')
    }

    return (
        <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                <div className="mt-3 mt-md-5">
                    <h4>
                        <Link
                            to={'/profile'}
                            className="text-decoration-none"
                        >←️</Link>
                        &nbsp;Удаление аккаунта
                    </h4>
                </div>
                <div className="alert alert-danger">
                    Внимание! Удаление аккаунта приведет к удалению всех ваших данных,
                    включая ваши доски, платежи, долги. При повторной регистрации ваши данные не будут восстановлены. Вы
                    уверены, что хотите удалить свой аккаунт?
                </div>
                <button
                    className="btn btn-danger"
                    onClick={handleDeleteProfile}
                >
                    Удалить аккаунт
                </button>
            </div>
        </div>
    )
}