import React, {useEffect, useRef, useState} from "react";

import Portal, {createContainer} from "./Portal";
import ScrollBarHelper from 'bootstrap/js/src/util/scrollbar';

const MODAL_CONTAINER_ID = "modal-container-id";

type Props = {
    onClose?: () => void;
    children: React.ReactNode | React.ReactNode[];
};

const Modal = (props: Props) => {
    const {onClose, children} = props;

    const rootRef = useRef(null);
    const [isMounted, setMounted] = useState(false);

    useEffect(() => {
        createContainer({id: MODAL_CONTAINER_ID});
        setMounted(true);
    }, []);

    useEffect(() => {
        const handleWrapperClick = (event: MouseEvent) => {
            const {target} = event;

            if (target instanceof Node && rootRef.current === target) {
                onClose?.();
            }
        };
        const handleEscapePress = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose?.();
            }
        };

        const scrollbar = new ScrollBarHelper()

        window.addEventListener("click", handleWrapperClick);
        window.addEventListener("keydown", handleEscapePress);
        scrollbar.hide()
        window.document.body.classList.remove('overflow-y-scroll')
        window.document.body.classList.add('overflow-y-hidden')

        return () => {
            window.removeEventListener("click", handleWrapperClick);
            window.removeEventListener("keydown", handleEscapePress);
            scrollbar.reset()
            window.document.body.classList.add('overflow-y-scroll')
            window.document.body.classList.remove('overflow-y-hidden')
        };
    }, [onClose]);

    return isMounted ? (
        <Portal id={MODAL_CONTAINER_ID}>
            <div className="modal-backdrop fade show"></div>
            <div className="modal fade show" style={{display: 'block'}} ref={rootRef} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        {children}
                    </div>
                </div>
            </div>
        </Portal>
    ) : null;
};

export default Modal;
