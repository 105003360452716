import SubscriptionFeatures from './SubscriptionFeatures';

export default class AuthUser {
    id: Number;
    name: String;
    avatar: Object;
    email: String;
    phone: Object;
    social: Object;
    developer: Boolean;
    subscription: ?Object;
    privacy: Object;

    #features: Map<String, Boolean>;

    constructor(data: Object) {
        this.id = data.id;
        this.name = data.name;
        this.avatar = data.avatar;
        this.email = data.email;
        this.phone = data.phone;
        this.social = data.social;
        this.developer = data.developer ?? false;
        this.subscription = data.subscription;
        this.privacy = data.privacy;
    }

    isPro(): boolean {
        return this.subscription?.plan.name === 'Pro';
    }

    features(): ?SubscriptionFeatures {
        if (!this.#features) {
            if (!this.subscription) {
                return null;
            }

            this.#features = new SubscriptionFeatures(this.subscription?.plan.features ?? {});
        }

        return this.#features;
    }

    isDeveloper(): boolean {
        return this.developer ?? false;
    }
}