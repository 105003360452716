export default function InvalidFeedback({error}) {
    if (!error) {
        return null;
    }

    return (
        <div className="invalid-feedback">
            {error}
        </div>
    )
}