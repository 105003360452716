import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useAppContext} from "../../context/appContext";

export default function RequireAuth() {
    const {auth} = useAppContext();
    const location = useLocation()

    if (auth.isAuthenticated()) {
        return <Outlet/>
    }

    if (location.pathname === '/') {
        return <Navigate to={'/login'}/>
    }

    const redirectTo = encodeURIComponent(location.pathname)

    return <Navigate to={'/login?redirectTo=' + redirectTo}/>
}