import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAppContext} from "../../context/appContext";
import {useStateBool} from '../../services/util/useStateBool';

export default function NotificationSettings() {
    const {api} = useAppContext()

    const [settings, setSettings] = useState([])
    const [channels, setChannels] = useState([])
    const [initialLoading, _, finishInitialLoading] = useStateBool(true)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        api.getNotificationSettings()
            .then(({data: settings, channels}) => {
                setSettings(settings)
                setChannels(channels)
                finishInitialLoading()
            })
    }, []);

    const handleSwitch = async (setting, channel) => {
        setLoading(true)
        try {
            const body = {
                settings: [
                    {
                        key: setting.key,
                        [channel.key]: !setting.channels[channel.key],
                    },
                ],
            };

            const {data: settings, channels} = await api.updateNotificationSettings(body)

            setSettings(settings)
            setChannels(channels)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                <div className="mt-3 mt-md-5">
                    <h4>
                        <Link
                            to={'/profile'}
                            className="text-decoration-none"
                        >←️</Link>
                        &nbsp;Настройки уведомлений
                    </h4>
                </div>
                <div className="card mt-2 overflow-hidden border-bottom-0">
                    {initialLoading
                        ? (
                            <table className="table mb-0">
                                <tbody>
                                {Array(5).fill(0).map((_, i) => (
                                    <tr key={'placeholder-' + i}>
                                        <td>
                                            <div className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )
                        : (
                            <table className="table mb-0">
                                <tbody>
                                <tr className="fw-bold">
                                    <td>Уведомление</td>
                                    {channels.map((channel, index) => (
                                        <td key={'ch-' + index}>
                                            {channel.title}
                                        </td>
                                    ))}
                                </tr>
                                {settings.map(setting => (
                                    <tr key={setting.key}>
                                        <td>
                                            {setting.title}
                                        </td>
                                        {channels.map(channel => (
                                            <td key={setting.key + '-' + channel.key}>
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={setting.channels[channel.key] === true}
                                                        disabled={loading}
                                                        onChange={() => handleSwitch(setting, channel)}
                                                    />
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )
                    }
                </div>
            </div>
        </div>
    )
}