import {useAppContext} from '../../context/appContext';
import {useEffect, useRef} from 'react';

export function ToastContainer({toasts}) {
    return (
        <div className="toast-container position-fixed p-3 top-0 start-50 translate-middle-x">
            {toasts.map((toast) => (
                <Toast key={toast.id} {...toast} />
            ))}
        </div>
    )
}

export const toastReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_TOAST':
            return {
                ...state,
                toasts: [...state.toasts, action.payload],
            };
        case 'DELETE_TOAST':
            const updatedToasts = state.toasts.filter(
                (toast) => toast.id !== action.payload,
            );
            return {
                ...state,
                toasts: updatedToasts,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export function Toast({id, message, type}) {
    const {toast} = useAppContext()

    const getClassName = () => {
        let classes = 'toast fade show align-items-center'

        if (type === 'error') {
            classes = classes + ' text-bg-danger border-0'
        }

        return classes
    }

    const timerID = useRef(null);

    const handleDismiss = () => {
        toast.remove(id)
    }

    useEffect(() => {
        timerID.current = setTimeout(() => {
            handleDismiss()
        }, 3000);

        return () => {
            clearTimeout(timerID.current);
        };
    }, []);

    return (
        <div
            className={getClassName()}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
        >
            <div className="d-flex">
                <div className="toast-body">
                    {message}
                </div>
                <button
                    type="button"
                    className="btn-close btn-close-white me-2 m-auto"
                    aria-label="Close"
                    onClick={handleDismiss}
                />
            </div>
        </div>
    )
}