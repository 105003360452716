import {useStateBool} from '../../../services/util/useStateBool';
import CreatePhantomModal from './CreatePhantomModal';

export default function CreatePhantom({existingPhantomNames, onSubmit}) {
    const [isModalOpened, openModal, closeModal] = useStateBool(false)

    return (
        <>
            <button
                className="btn btn-primary me-2"
                onClick={openModal}
            >
                Добавить фантома
            </button>

            <CreatePhantomModal
                existingPhantomNames={existingPhantomNames}
                isOpen={isModalOpened}
                onClose={closeModal}
                onSubmit={onSubmit}
            />
        </>
    )
}