export function useShare() {
    const allowed = navigator.share !== undefined

    const shareUrl = async (str) => {
        try {
            await navigator.share({
                url: str,
            })
        } catch (e) {
            console.log('share is not supported')
        }
    }

    return {allowed, shareUrl}
}