import {Link, Navigate, useNavigate, useParams} from 'react-router-dom';
import {useAppContext} from '../context/appContext';
import React, {useEffect, useState} from 'react';
import Avatar from './Avatar/Avatar';
import AvatarPlaceholder from './Avatar/AvatarPlaceholder';

export default function Invite() {
    const {token} = useParams()
    const navigate = useNavigate()

    const {api} = useAppContext()

    const [inviteInfo, setInviteInfo] = useState({
        loading: true,
        accepted: null,
        data: null,
        notFound: false,
    })

    useEffect(() => {
        api.getInviteInfo(token)
            .then(({accepted, data}) => {
                setInviteInfo({
                    loading: false,
                    accepted,
                    data,
                    notFound: false,
                })
            })
            .catch(e => {
                console.log(e)

                if (e?.response?.status === 401) {
                    navigate('/login')
                }

                setInviteInfo({
                    loading: false,
                    accepted: null,
                    data: null,
                    notFound: true,
                })
            })
    }, []);

    const handleAccept = async () => {
        const {boardId} = await api.acceptInvite(token)
        navigate('/boards/' + boardId)
    }

    if (inviteInfo.loading) {
        return (
            <InvitePlaceholder/>
        )
    }

    if (inviteInfo.notFound) {
        return (
            <>
                <h1>Invite not found</h1>
            </>
        )
    }

    if (inviteInfo.accepted) {
        return <Navigate to={'/boards/' + inviteInfo.data.boardId}/>
    }

    return (
        <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col-11 col-sm-8 col-md-6 col-lg-4 col-xl-3">
                    <div className="card my-auto border-0 mt-5 py-3 px-2 shadow-sm bg-secondary-subtle">
                        <div className="card-body text-center">
                            <Avatar
                                className="mx-auto mb-4"
                                emoji={inviteInfo.data.avatar.emoji}
                                color={inviteInfo.data.avatar.color}
                                size="xlarge"
                            />
                            <h5 className="card-title fw-bolder">{inviteInfo.data.name}</h5>
                            <p className="fw-lighter mb-2">
                                {textMembersTotal(inviteInfo.data.membersTotal)}
                            </p>
                            <div className="d-flex justify-content-center mb-4">
                                {inviteInfo.data.members.map((m, i) => (
                                    <Avatar
                                        key={i}
                                        emoji={m.avatar.emoji}
                                        color={m.avatar.color}
                                        className="me-1"
                                    />
                                ))}
                                {inviteInfo.data.members.length < inviteInfo.data.membersTotal && (
                                    <div className="ms-1 fw-light"
                                         style={{fontSize: '0.75rem', lineHeight: '24px'}}>
                                        {'+' + (inviteInfo.data.membersTotal - inviteInfo.data.members.length)}
                                    </div>
                                )}
                            </div>
                            {inviteInfo.data.phantom && (
                                <div className="alert alert-light text-center">
                                    <p className="mb-0">
                                        Это приглашение фантома.
                                    </p>
                                    <p className="mb-0">
                                        Вы вступите как <strong>{inviteInfo.data.phantom.name}</strong>
                                        {/*todo ссылка на faq*/}
                                    </p>
                                </div>
                            )}
                        </div>
                        <button
                            className="btn btn-primary"
                            onClick={handleAccept}
                        >
                            Вступить
                        </button>

                        <Link to="/" className="btn btn-link text-decoration-none">
                            Отмена
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

// todo new layout
function InvitePlaceholder() {
    return (
        <div className="d-flex align-items-center vh-100">
            <div className="w-100 m-auto">
                <div className="d-flex mb-3">
                    <div className="d-flex mx-auto">
                        <AvatarPlaceholder size={'large'}/>
                    </div>
                </div>

                <h1 className="h3 mb-3 text-center placeholder-glow">
                    <span className="placeholder" style={{width: '150px'}}/>
                </h1>

                <div className="d-flex mb-3">
                    <div className="d-flex mx-auto">
                        {[...Array(3).keys()].map((i) => (
                            <div key={i} className="d-flex">
                                <AvatarPlaceholder/>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="d-flex mb-3">
                    <div className="d-flex mx-auto placeholder-glow">
                        <a
                            href="#"
                            className="btn btn-primary disabled placeholder col-3"
                            style={{
                                width: '100px',
                            }}
                            aria-disabled={true}
                        />
                        <a
                            href="#"
                            className="btn btn-secondary disabled placeholder col-3 ms-2"
                            style={{
                                width: '100px',
                            }}
                            aria-disabled={true}
                        >
                            <span className="placeholder"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

const textMembersTotal = total => {
    const lastDigit = total % 10
    const lastTwoDigits = total % 100

    if (lastDigit === 1 && lastTwoDigits !== 11) {
        return `${total} участник`
    }
    if (lastDigit > 0 && lastDigit < 5 && (lastTwoDigits < 10 || lastTwoDigits > 20)) {
        return `${total} участника`
    }
    return `${total} участников`
}