export function setThemeToLocalStorage(theme) {
    localStorage.setItem('theme', theme);
}

export function getThemeFromLocalStorage() {
    return localStorage.getItem('theme');
}

export function setThemeToDocument(theme) {
    document.documentElement.setAttribute('data-bs-theme', theme);
}

export function getThemeFromDocument() {
    return document.documentElement.getAttribute('data-bs-theme');
}