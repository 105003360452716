import {useState} from 'react';
import {useShare} from '../../../services/util/share';
import {removeSchemeFromUrl} from '../../../services/util/removeSchemeFromUrl';

export default function Invite({inviteUrl, canRegenerate, onRegenerate, hasPhantoms}) {
    const [copyIconClass, setCopyIconClass] = useState('bi bi-clipboard')
    const [regenerateIconClass, setRegenerateIconClass] = useState('bi bi-arrow-repeat')
    const {allowed: shareAllowed, shareUrl} = useShare()

    const handleCopy = async () => {
        await navigator.clipboard.writeText(inviteUrl);
        setCopyIconClass('bi bi-clipboard-check')
        setTimeout(() => {
            setCopyIconClass('bi bi-clipboard')
        }, 1000)
    }

    const handleRegenerate = async () => {
        await onRegenerate()
        setRegenerateIconClass('bi bi-check2')
        setTimeout(() => {
            setRegenerateIconClass('bi bi-arrow-repeat')
        }, 1000)
    }

    const handleShare = async () => await shareUrl(inviteUrl)

    return (
        <div>
            <h5>Приглашение</h5>

            <div className="input-group mb-3">
                <input
                    className="form-control"
                    type="text"
                    value={removeSchemeFromUrl(inviteUrl)}
                    readOnly
                />
                {shareAllowed && (
                    <button
                        className="btn btn-outline-secondary"
                        onClick={handleShare}
                    >
                        <i className="bi bi-share"></i>
                    </button>
                )}
                <button
                    className="btn btn-outline-secondary"
                    onClick={handleCopy}
                >
                    <i className={copyIconClass}></i>
                </button>
                {canRegenerate && (
                    <button
                        className="btn btn-outline-secondary"
                        onClick={handleRegenerate}
                    >
                        <i className={regenerateIconClass}></i>
                    </button>
                )}
            </div>

            {hasPhantoms && (
                <div className="alert alert-light">
                    Внимание! Для того, чтобы пригласить кого-то на место фантома, необходимо использовать персональную
                    ссылку фантома
                </div>
            )}
        </div>
    )
}