import {useAppContext} from '../../context/appContext';
import React, {useEffect, useState} from 'react';
import {useStateBool} from '../../services/util/useStateBool';
import Avatar from '../Avatar/Avatar';

export default function Debts({board}) {
    const {api, auth, toast} = useAppContext()

    const me = auth.getUser()

    const [initialLoading, _, finishInitialLoading] = useStateBool(true)
    const [loading, setLoading] = useState(false)
    const [debts, setDebts] = useState([])

    useEffect(() => {
        loadDebts().then(() => {
            finishInitialLoading()
        })
    }, [])

    const loadDebts = async () => {
        const {debts, balances, debtors, creditors} = await api.getBoardDebts(board.id)
        setDebts(debts)
    }

    const handleReturnDebt = async (debt) => {
        if (loading) {
            return
        }
        if (!window.confirm('Вы уверены, что хотите записать возврат долга?')) {
            return
        }
        setLoading(true)

        try {
            const myMemberId = board.members.find(m => m.user?.id === me.id).id

            await api.createBoardTransfer(board.id, {
                amount: debt.amount.raw,
                currencyId: board.currency.id,
                paidAt: new Date(),
                payerId: myMemberId,
                payeeId: debt.creditor.id,
            })
            await loadDebts()
        } finally {
            setLoading(false)
        }
    }

    const handleMercyDebt = async (debt) => {
        if (loading) {
            return
        }
        if (!window.confirm('Вы уверены, что хотите простить долг?')) {
            return
        }
        setLoading(true)

        try {
            await api.mercyDebt(board.id, debt.debtor.id)
            await loadDebts()
        } finally {
            setLoading(false)
        }
    }

    const handleRemind = async (debt) => {
        if (loading) {
            return
        }
        setLoading(true)

        try {
            await api.remindAboutDebt(board.id, debt.debtor.id)
            toast.info('Напоминание отправлено')
        } finally {
            setLoading(false)
        }
    }

    const isMyDebt = debt => {
        return debt.debtor.user?.id === me.id
            || debt.creditor.user?.id === me.id
    }

    if (initialLoading) {
        return <DebtsPlaceholder/>
    }

    return (
        <div className="row">
            <div className="col-12 col-md-6">
                <MyDebts
                    debts={debts.filter(isMyDebt)}
                    readonly={board.isArchived}
                    onReturn={handleReturnDebt}
                    onMercy={handleMercyDebt}
                    onRemind={handleRemind}
                />
            </div>

            <div className="col-12 col-md-6">
                <OtherDebts
                    debts={debts.filter(debt => !isMyDebt(debt))}
                />
            </div>
        </div>
    )
}

function MyDebts({debts, readonly, onRemind, onReturn, onMercy}) {
    const {auth} = useAppContext()

    const me = auth.getUser()

    if (debts.length === 0) {
        return (
            <div className="card border-0 bg-body-secondary mb-3">
                <div className="card-body">
                    У вас нет долгов
                </div>
            </div>
        )
    }

    const title = debts[0].debtor.user?.id === me.id
        ? 'Вы должны'
        : 'Вам должны';

    return (
        <>
            <h5>{title}</h5>
            {debts.map((debt, i) => (
                <div key={i} className="card border-0 bg-body-secondary mb-3">
                    <div className="card-body">
                        <div className="d-flex justify-content-between mb-1">
                            <div className="h6 d-flex">
                                <Person
                                    member={debt.debtor.user?.id === me.id ? debt.creditor : debt.debtor}
                                />
                            </div>
                            <p className="h6">
                                {debt.amount.format()}&nbsp;{debt.currency.symbol}
                            </p>
                        </div>

                        {!readonly && (
                            <>
                                {debt.debtor.user?.id === me.id && (
                                    <button
                                        className="btn btn-primary btn-sm me-2"
                                        onClick={() => onReturn(debt)}
                                    >
                                        Записать возврат
                                    </button>
                                )}

                                {debt.creditor.user?.id === me.id && (
                                    <button
                                        className="btn btn-primary btn-sm me-2"
                                        onClick={() => onMercy(debt)}
                                    >
                                        Простить
                                    </button>
                                )}

                                {debt.creditor.user?.id === me.id && !!debt.debtor.user && me.features()?.reminders && (
                                    <button
                                        className="btn btn-primary btn-sm me-2"
                                        onClick={() => onRemind(debt)}
                                    >
                                        Напомнить
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            ))}
        </>
    )
}

function Person({member}) {
    return (
        <>
            <Avatar emoji={member.avatar().emoji} color={member.avatar().color}/>
            <span className="ms-2 my-auto">{member.name()}</span>
        </>
    )
}

function OtherDebts({debts}) {
    if (debts.length === 0) {
        return (
            <></>
        )
    }

    return (
        <>
            <h5>Остальные долги</h5>
            <ul className="list-group list-group-flush">
                {debts.map((debt, i) => (
                    <li
                        key={i}
                        className="list-group-item d-flex justify-content-between align-items-start px-0"
                    >
                        <div className="h6">
                            {debt.debtor.name()} → {debt.creditor.name()}
                        </div>
                        <div className="h6">
                            {debt.amount.format()}&nbsp;{debt.currency.symbol}
                        </div>
                    </li>
                ))}
            </ul>
        </>
    )
}

function DebtsPlaceholder() {
    return (
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )
}